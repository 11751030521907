import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import styles from './invitation-details.module.scss';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {RouteUrl} from '../../routes';
import {AdminClientParams} from '../../types/admin-client-params';
import {AppContext} from '../../store/app-context';
import {Invitation, InvitationStatus} from '../../types/invitation';
import {InvitationActionTypes} from '../../store/invitation/types';
import BankApi from '../../api/bank-api';
import TitleWithBack from '../../components/title-with-back/title-with-back.component';
import {VariableDisplay} from '../../types/variable-display';
import {InvitationConfiguration} from '../../types/invitation-configuration';
import {UserRole} from '../../types/user';
import {buildWebformUrl} from '../../utils/webform-url-builder';

const InvitationDetailsPage: FunctionComponent = () => {
    const {state, dispatch} = useContext(AppContext);
    const bank = state.bankState.bank;
    const {t} = useTranslation();
    const history = useHistory();
    const {bankId, invitationId} = useParams<AdminClientParams>();
    const [invitation, setInvitation] = useState<Invitation | undefined>(state.invitationState.invitation);
    const [configuration, setConfiguration] = useState<InvitationConfiguration>();

    useEffect(() => {
        if (!bankId || !bank) {
            history.push(RouteUrl.Root);
        }
    }, [bankId]);

    useEffect(() => {
        if (!invitation) {
            if (state.invitationState.invitation) {
                setInvitation(state.invitationState.invitation);
            } else {
                getInvitation();
            }
        }
    }, []);

    useEffect(() => {
        if (state.bankState.invitationConfiguration) {
            setConfiguration(state.bankState.invitationConfiguration);
        }
    }, [state.bankState.invitationConfiguration]);

    const navigateTo = (route: RouteUrl) => {
        history.push(route.replace(':bankId', bankId));
        dispatch({type: InvitationActionTypes.SetInvitation, invitation: undefined});
    }

    const getInvitation = async () => {
        const response = await BankApi.getInvitation(bankId, invitationId);
        setInvitation(response);
    }

    const openWebform = () => {
        if (invitation && canAccessWebform()) {
            const webformUrl = buildWebformUrl(bankId, invitation.token);
            window.open(webformUrl, '_blank');
        }
    }

    const canAccessWebform = (): boolean => {
        if (configuration && invitation) {
            return configuration.displayEmail === VariableDisplay.Hidden && invitation.status === InvitationStatus.PENDING &&
                (state.userState.user?.role === UserRole.Admin || state.userState.user?.username === invitation.createdBy);
        } else {
            return false;
        }
    }

    return invitation && configuration ? (
        <div className={styles.page}>
            <div className={styles.content}>
                <TitleWithBack title={t('Invitation Details')} onBackClick={() => {navigateTo(RouteUrl.Invitations)}}/>
                <div className={styles.panel}>
                    <div className={styles.panelTitle}>
                        {t('Invitation Details')}
                    </div>
                    {configuration.displayRealEstateType !== VariableDisplay.Hidden && <div className={styles.field}>
                        <div className={styles.label}>{t('Real estate type')}:</div>
                        <div className={styles.value}>{invitation.realEstateType}</div>
                    </div>}
                    <div className={styles.field}>
                        <div className={styles.label}>{t('Name')}:</div>
                        <div className={styles.value}>{invitation.firstName} {invitation.lastName}</div>
                    </div>
                    {configuration.displayEmail !== VariableDisplay.Hidden && <div className={styles.field}>
                        <div className={styles.label}>{t('Email')}:</div>
                        <div className={styles.value}>{invitation.email}</div>
                    </div>}
                    {invitation.secondaryEmail && <div className={styles.field}>
                        <div className={styles.label}>{t('Second email')}:</div>
                        <div className={styles.value}>{invitation.secondaryEmail}</div>
                    </div>}
                    <div className={styles.field}>
                        <div className={styles.label}>{t('Language')}:</div>
                        <div className={styles.value}>{invitation.language}</div>
                    </div>
                    {invitation.address && <div className={styles.field}>
                        <div className={styles.label}>{t('Address')}:</div>
                        <div className={styles.value}>{invitation.address}</div>
                    </div>}
                    {invitation.externalRef && <div className={styles.field}>
                        <div className={styles.label}>{t('Reference')}:</div>
                        <div className={styles.value}>{invitation.externalRef}</div>
                    </div>}
                    {invitation.nsxId && <div className={styles.field}>
                        <div className={styles.label}>{t('NSX Id')}:</div>
                        <div className={styles.value}>{invitation.nsxId}</div>
                    </div>}
                    <div className={styles.field}>
                        <div className={styles.label}>{t('Status')}:</div>
                        <div className={styles.value}>{invitation.status}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>{t('Token')}:</div>
                        <div className={styles.value}>{invitation.token}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>{t('Valuation needed')}:</div>
                        <div className={styles.value}>{invitation.valuationNeeded ? t('Yes') : t('No')}</div>
                    </div>
                    {configuration.displayFullVisitValuation !== VariableDisplay.Hidden && <div className={styles.field}>
                        <div className={styles.label}>{t('Full visit valuation')}:</div>
                        <div className={styles.value}>{invitation.fullVisitValuation ? t('Full Visit') : t('Desktop')}</div>
                    </div>}
                    {configuration.displayFullPropertyRights !== VariableDisplay.Hidden && <div className={styles.field}>
                        <div className={styles.label}>{t('Full property rights')}:</div>
                        <div className={styles.value}>{invitation.fullPropertyRights ? t('Yes') : t('No')}</div>
                    </div>}
                    {configuration.displayLoanPurpose !== VariableDisplay.Hidden && <div className={styles.field}>
                        <div className={styles.label}>{t('Purpose of the loan')}:</div>
                        <div className={styles.value}>{invitation.loanPurpose ? t(invitation.loanPurpose) : ''}</div>
                    </div>}
                    {configuration.displayTransactionValue !== VariableDisplay.Hidden && <div className={styles.field}>
                        <div className={styles.label}>{t('Transaction/estimated value')}:</div>
                        <div className={styles.value}>{invitation.transactionValue}</div>
                    </div>}
                    {configuration.displayTransactionType !== VariableDisplay.Hidden && <div className={styles.field}>
                        <div className={styles.label}>{t('Transaction type')}:</div>
                        <div className={styles.value}>{invitation.transactionType}</div>
                    </div>}
                    {configuration.displayTransactionYear !== VariableDisplay.Hidden && <div className={styles.field}>
                        <div className={styles.label}>{t('Year transaction/estimated value')}:</div>
                        <div className={styles.value}>{invitation.transactionYear}</div>
                    </div>}
                    {configuration.displayRenovationBudget !== VariableDisplay.Hidden && <div className={styles.field}>
                        <div className={styles.label}>{t('Total budget renovation works')}:</div>
                        <div className={styles.value}>{invitation.renovationBudget}</div>
                    </div>}
                    <div className={styles.field}>
                        <div className={styles.label}>{t('Payment Strategy')}:</div>
                        <div className={styles.value}>{t(invitation.paymentStrategy)}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>{t('Extra Information')}:</div>
                        <div className={styles.value}>{t(invitation.extraInformation)}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>{t('Created by')}:</div>
                        <div className={styles.value}>{invitation.createdBy}</div>
                    </div>
                    <div className={styles.field}>
                        <div className={styles.label}>{t('Created On')}:</div>
                        <div className={styles.value}>{new Date(invitation.createdOn).toLocaleDateString()}</div>
                    </div>
                    
                </div>
                <div className={`${styles.buttons} ${styles.alignLeft}`}>
                    <button className={`${styles.button} ${styles.negative}`} onClick={() => navigateTo(RouteUrl.Invitations)}>{t('Back')}</button>
                    {canAccessWebform() &&
                        <button className={styles.button} onClick={() => openWebform()}>{t('Go to webform')}</button>}
                </div>
            </div>
        </div>
    ) : null;
}

export default InvitationDetailsPage;
