import {PaymentStrategy} from '../bank';
import {RealEstateType} from '../invitation';

export interface InvitationRequest {
    firstName: string;
    lastName: string;
    email: string;
    address?: string;
    reference?: string;
    language: string;
    fullVisitValuation?: boolean;
    loanPurpose?: LoanPurpose;
    transactionValue: number;
    transactionType?: TransactionType;
    transactionYear: number;
    renovationBudget?: number;
    accountManager: string;
    paymentStrategy: PaymentStrategy;
    fullPropertyRights?: boolean;
    valuationNeeded: boolean;
    secondaryEmail?: string;
    nsxId?: string;
    clusterId?: string;
    clusterName?: string;
    realEstateType: RealEstateType;
    extraInformation?: string
}

// t('Purchase')
// t('NewBuilding')
// t('TotalRenovation')
// t('PartialRenovation')
// t('PurchaseAndTotalRenovation')
// t('PurchaseAndPartialRenovation')
export enum LoanPurpose {
    Purchase = 'Purchase',
    NewBuilding = 'NewBuilding',
    TotalRenovation = 'TotalRenovation',
    PartialRenovation = 'PartialRenovation',
    PurchaseAndTotalRenovation = 'PurchaseAndTotalRenovation',
    PurchaseAndPartialRenovation = 'PurchaseAndPartialRenovation',
}

// t('Compromise')
// t('AskingPrice')
// t('OwnValuation')
export enum TransactionType {
    Compromise = 'Compromise',
    AskingPrice = 'AskingPrice',
    OwnValuation = 'OwnValuation',
}
