// t('EmailAscending')
// t('EmailDescending')
// t('RoleAscending')
// t('RoleDescending')
// t('CreatedOnAscending')
// t('CreatedOnDescending')
export enum UserSortOrder {
    EmailAscending = 'EmailAscending',
    EmailDescending = 'EmailDescending',

    RoleAscending = 'RoleAscending',
    RoleDescending = 'RoleDescending',

    CreatedOnAscending = 'CreatedOnAscending',
    CreatedOnDescending = 'CreatedOnDescending',
}
