import axios, {AxiosRequestConfig} from 'axios';
import {buildBaseUrl} from '../api/api-urls';
import AuthenticationApi from '../api/authentication-api';

export class Http {
    public static async get<T>(
        path: string,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
        skipAuth?: boolean
    ): Promise<T> {
        const url = Http.buildUrl(path, parameters);
        const requestHeaders = skipAuth ? headers : await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
        };

        const result = await axios.get<T>(url, configuration);
        return result.data;
    }

    public static async post<TBody, T>(
        path: string,
        body: TBody,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<T> {
        const url = Http.buildUrl(path, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
        };

        const result: any = await axios.post<T>(url, body, configuration);
        return result.data;
    }

    public static async put<TBody, T>(
        path: string,
        body: TBody,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<T> {
        const url = Http.buildUrl(path, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
        };

        const result = await axios.put<T>(url, body, configuration);
        return result.data;
    }

    public static async delete<T>(
        path: string,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<T> {
        const url = Http.buildUrl(path, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
        };

        const result = await axios.delete<T>(url, configuration);
        return result.data;
    }

    public static async getBlob<Blob>(
        path: string,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<Blob> {
        const url = Http.buildUrl(path, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
            responseType: 'blob',
        };

        const result = await axios.get<Blob>(url, configuration);
        return result.data;
    }

    public static async getBlobWithPost<TBody, Blob>(
        path: string,
        body: TBody,
        parameters?: { [key: string]: string | number },
        queryParameters?: { [key: string]: string | number },
        headers?: { [key: string]: string | number },
    ): Promise<Blob> {
        const url = Http.buildUrl(path, parameters);
        const requestHeaders = await this.buildHeaders(headers);
        const configuration: AxiosRequestConfig = {
            params: queryParameters,
            headers: requestHeaders,
            responseType: 'blob',
        };

        const result = await axios.post<Blob>(url, body, configuration);
        return result.data;
    }

    public static buildUrl(path: string, parameters?: { [key: string]: string | number }): string {
        const url = `${buildBaseUrl()}${path}`;
        if (parameters) {
            return Object.keys(parameters).reduce((url, parameterKey) => url.replace(`{${parameterKey}}`, parameters[parameterKey].toString()), url);
        }
        return url;
    }

    public static async buildHeaders(headers?: {
        [key: string]: string | number;
    }): Promise<{ [key: string]: string | number }> {
        const token = await AuthenticationApi.getJwtIdToken();
        const baseHeaders = {
            Authorization: `Bearer ${token}`,
        };
        if (headers) {
            return {...baseHeaders, ...headers};
        }
        return baseHeaders;
    }
}
