import React, {FormEvent, FunctionComponent, useContext, useEffect, useState} from 'react';
import styles from './create-user.module.scss';
import {useTranslation} from 'react-i18next';
import {Regex} from '../../types/regex';
import ReactTooltip from 'react-tooltip';
import exclamationMarkIcon from '../../../assets/images/icon_Exclamation Mark.svg';
import {conditionalClassLister} from '../../utils/class-helper';
import {AppContext} from '../../store/app-context';
import {LayoutActions} from '../../store/layout/types';
import {ToastMessage, ToastMessageType} from '../../types/toast';
import DropdownComponent, {SelectionOption} from '../../components/dropdown/dropdown.component';
import {useHistory, useParams} from 'react-router-dom';
import {RouteUrl} from '../../routes';
import {AdminClientParams} from '../../types/admin-client-params';
import LoaderComponent from '../../components/loader/loader';
import {User, UserRole} from '../../types/user';
import UserApi from '../../api/user-api';
import TitleWithBack from '../../components/title-with-back/title-with-back.component';
import {ErrorContract} from '../../types/error';

const CreateUserPage: FunctionComponent = () => {
    const [email, setEmail] = useState<string>('');
    const [role, setRole] = useState<UserRole>(UserRole.User);
    const [errors, setErrors] = useState<loginErrors>({email: undefined});
    const [inProgress, setInProgress] = useState<boolean>(false);
    const {dispatch, state} = useContext(AppContext);
    const {t} = useTranslation();
    const history = useHistory();
    const {bankId} = useParams<AdminClientParams>();
    const mailRegex = new RegExp(Regex.Email);

    const roleOptions: SelectionOption[] = [
        {key: UserRole.User, value: UserRole.User, label: t('User')},
        {key: UserRole.Admin, value: UserRole.Admin, label: t('Admin')},
    ];

    useEffect(() => {
        if (!bankId || !state.bankState.bank) {
            history.push(RouteUrl.Root);
        }
    }, [bankId]);


    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        let isValid = true;
        if (!email || !mailRegex.test(email)) {
            isValid = false;
            setErrors((prevValue) => setEmailError(t('Invalid email'), prevValue))
        }
        if (isValid) {
            setErrors((prevValue) => ({
                ...prevValue, ...{
                    "email": undefined
                }
            }));
            if (!bankId || bankId === 'undefined') {
                dispatch({
                    type: LayoutActions.AddToast,
                    toast: new ToastMessage(ToastMessageType.Error, t('Could not find the correct bank. Please refresh the page and try again.')),
                });
            } else if (bankId) {
                submitUserForm(bankId);
                setEmail("");
                setErrors({});
            } else {
                dispatch({
                    type: LayoutActions.AddToast,
                    toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.')),
                });
            }
        }
    }
    const submitUserForm = async (bankId: string) => {
        setInProgress(true);
        await UserApi.getUserByUsername(email).then(async (user) => {
            if (user) {
                if (user.role === UserRole.Admin) {
                    await UserApi.linkUserToBank(user.id, bankId).then((response) => {
                        dispatch({
                            type: LayoutActions.AddToast,
                            toast: new ToastMessage(ToastMessageType.Success, t('User has been linked to bank successfully.')),
                        });
                    }).catch((error: ErrorContract) => {
                        dispatch({
                            type: LayoutActions.AddToast,
                            toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.')),
                        });
                    })
                } else {
                    dispatch({
                        type: LayoutActions.AddToast,
                        toast: new ToastMessage(ToastMessageType.Error, t('The user does not have the correct permissions.')),
                    });
                }
            } else {
                await UserApi.createUser(email, role, bankId).then((user) => {
                    dispatch({
                        type: LayoutActions.AddToast,
                        toast: new ToastMessage(ToastMessageType.Success, t('User has been created successfully.')),
                    });
                }).catch((error) => {
                    dispatch({
                        type: LayoutActions.AddToast,
                        toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.')),
                    });
                });
            }
        });

        setInProgress(false);
    }

    const navigateTo = (route: RouteUrl) => {
        history.push(route.replace(':bankId', bankId));
    }

    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <div className={styles.panel}>
                    <TitleWithBack title={t('Create User')} onBackClick={() => {
                        navigateTo(RouteUrl.Users)
                    }}></TitleWithBack>
                    <form>
                        <div className={styles.formField}>
                            <div className={styles.labelContainer}>
                                <label className={styles.label} htmlFor="email">
                                    {t('Email')}
                                </label>
                            </div>
                            <div>
                                <input type="text"
                                       className={conditionalClassLister(styles)({
                                           input: true,
                                           inputInvalid: errors.email,
                                       })}
                                       id="email"
                                       value={email}
                                       onChange={(e) => setEmail(e.target.value)}/>
                                {errors.email ?
                                    <div className={styles.invalid}>
                                        <ReactTooltip effect="solid"/>
                                        <img src={exclamationMarkIcon} data-tip={errors.email}></img>
                                    </div> : null}
                            </div>
                        </div>
                        <div className={styles.formField}>
                            <div className={styles.labelContainer}>
                                <label className={styles.label} htmlFor="role">
                                    {t('Role')}
                                </label>
                            </div>
                            <div className={styles.dropdownInput}>
                                <DropdownComponent options={roleOptions} onSelectionChanged={(role) => {
                                    if (role === UserRole.User) {
                                        setRole(UserRole.User);
                                    }
                                    if (role === UserRole.Admin) {
                                        setRole(UserRole.Admin);
                                    }
                                }} selectedOptionValue={role}/>
                            </div>
                        </div>
                        <div className={styles.formField}>
                            <div className={`${styles.buttons} ${styles.alignLeft}`}>
                                <button type='button' className={`${styles.button} ${styles.negative}`}
                                        onClick={() => navigateTo(RouteUrl.Users)}>{t('Back')}</button>
                                <button type='submit' className={styles.button} onClick={(e) => handleFormSubmit(e)}>
                                    {inProgress ?
                                        <div className={styles.loadingIcon}>
                                            <LoaderComponent/>
                                        </div> : t('Create')}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default CreateUserPage;

export type loginErrors = {
    "email"?: string,
}
export const setEmailError = (errorMessage: string, loginErrors: loginErrors): loginErrors => {
    return ({
        ...loginErrors,
        ...{
            "email": errorMessage
        }
    })
}
