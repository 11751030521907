import {Bank} from './bank';

export interface User {
    id: string;
    username: string;
    banks: Bank[];
    role: UserRole;
    createdOn: Date;
}

export enum UserRole {
    Admin = 'admin',
    User = 'user',
}
