import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import styles from './users.page.module.scss';
import {useTranslation} from 'react-i18next';
import BankApi from '../../api/bank-api';
import {useHistory, useParams} from 'react-router-dom';
import {RouteUrl} from '../../routes';
import {AdminClientParams} from '../../types/admin-client-params';
import LoaderComponent from '../../components/loader/loader';
import {AppContext} from '../../store/app-context';
import {enumToSelectionOptions} from '../../components/dropdown/selection-option.util';
import {UsersRequest} from '../../types/request/users-request';
import {UserSortOrderMapper} from '../../utils/user-sort-order.mapper';
import {UserSortOrder} from '../../types/user-sort-order';
import {ReactComponent as SearchIconComponent} from '../../../assets/images/search.svg';
import DropdownComponent from '../../components/dropdown/dropdown.component';
import PagingComponent from '../../components/paging/paging.component';
import {PopupActionTypes, PopupSize} from '../../store/popup/types';
import ConfirmationPopupComponent from '../../components/confirm-delete-popup/confirmation-popup.component';
import {User} from '../../types/user';
import {ReactComponent as TrashIconComponent} from '../../../assets/images/trash.svg';
import UserApi from '../../api/user-api';
import {LayoutActions} from '../../store/layout/types';
import {ToastMessage, ToastMessageType} from '../../types/toast';


const UsersPage: FunctionComponent = () => {
    const {state, dispatch} = useContext(AppContext);
    const [users, setUsers] = useState<User[]>([]);

    const {t} = useTranslation();
    const history = useHistory();
    const {bankId} = useParams<AdminClientParams>();
    const [inProgress, setInProgress] = useState<boolean>(true);
    const [filter, setFilter] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(50);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [sort, setSort] = useState<UserSortOrder>(UserSortOrder.EmailAscending);
    const sortOptions = enumToSelectionOptions(UserSortOrder, t);

    useEffect(() => {
        if (!bankId || !state.bankState.bank) {
            history.push(RouteUrl.Root);
        } else {
            getUsers(bankId);
        }
    }, [bankId, sort, page, itemsPerPage]);


    const getUsers = async (bankId: string) => {
        setInProgress(true);
        setUsers([]);
        const request: UsersRequest = {
            page,
            itemsPerPage,
            filter: filter.trim(),
            sorting: UserSortOrderMapper.mapToSortingOptions(sort),
        }
        const result = await BankApi.getUsersForBank(bankId, request);
        setUsers(result.items);
        setTotalItems(result.count);
        setInProgress(true);
    }

    const handleKeyPress = (event: any) => {
        if (event.code === 'Enter') {
            setPage(1);
            getUsers(bankId);
        }
    }

    const onSortOptionChanged = (optionValue: string) => {
        setSort(UserSortOrder[optionValue as keyof typeof UserSortOrder]);
    };

    const onPreviousPage = () => {
        setPage(page - 1);
    };

    const onNextPage = () => {
        setPage(page + 1);
    };

    const onJumpToPage = (index: number) => {
        if (!isNaN(index)) {
            setPage(index);
        }
    };

    const onItemsPerPageChanged = (amount: number) => {
        setPage(1);
        setItemsPerPage(amount);
    };

    const navigateTo = (route: RouteUrl) => {
        if (bankId && state.bankState.bank) {
            history.push(route.replace(':bankId', bankId));
        }
    }

    const openConfirmDeletePopup = (event: React.MouseEvent<HTMLSpanElement>, user: User) => {
        event.stopPropagation();
        dispatch({
            type: PopupActionTypes.ShowPopup,
            popup: {
                content:
                    <ConfirmationPopupComponent
                        title={t('Confirm deletion')}
                        message={t('Are you sure you want to delete user {{user}}?', {user: user.username})}
                        callback={ () => handleUserDelete(user.id)}
                    />,
                size: PopupSize.Small,
            },
        });
    }

    const handleUserDelete = async (userId: string) => {
        await UserApi.deleteUser(bankId, userId).then(async (response) => {
            await getUsers(bankId);
            dispatch({
                type: PopupActionTypes.ClosePopup,
            });
            dispatch({
                type: LayoutActions.AddToast,
                toast: new ToastMessage(ToastMessageType.Success, t('User deleted successfully')),
            });
        }).catch((err) => {
            dispatch({
                type: LayoutActions.AddToast,
                toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.')),
            });
        });
    }

    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <div className={styles.title}>{t('Users')}</div>
                <div className={styles.contentTop}>
                    <div className={styles.tableControls}>
                        <div className={styles.searchBar}>
                            <div className={styles.searchIconPanel}>
                                <SearchIconComponent className={styles.searchIcon}/>
                            </div>
                            <input type="text" className={styles.input}
                                   autoFocus={true}
                                   value={filter}
                                   onChange={(e) => setFilter(e.target.value)}
                                   onKeyPress={(e) => handleKeyPress(e)}
                                   placeholder={t('Search by email')}
                            />
                        </div>
                        <div className={styles.sorting}>
                            <DropdownComponent
                                options={sortOptions}
                                selectedOptionValue={sort}
                                onSelectionChanged={(optionValue) => onSortOptionChanged(optionValue)}/>
                        </div>
                    </div>
                    <div className={styles.buttons}>
                        <button className={styles.button}
                                onClick={() => navigateTo(RouteUrl.CreateUser)}>{t('Create User')}</button>
                    </div>
                </div>
                <table>
                    <thead>
                    <tr>
                        <th className={styles.small}>{t('Email')}</th>
                        <th className={styles.small}>{t('Created On')}</th>
                        <th className={styles.small}>{t('Role')}</th>
                        <th className={styles.extraSmall}/>
                    </tr>
                    </thead>
                    <tbody>
                    {users && users.length > 0 ? users.map((user) =>
                        <tr key={user.id}>
                            <td className={styles.small}>{user.username}</td>
                            <td className={styles.small}>{new Date(user.createdOn).toLocaleDateString()}</td>
                            <td className={styles.small}>{user.role}</td>
                            <td className={styles.extraSmall}>
                                <span className={styles.deleteIconContainer} onClick={(event) => openConfirmDeletePopup(event, user)}>
                                    <TrashIconComponent className={styles.icon}/>
                                </span>
                            </td>
                        </tr>) :
                        <tr>
                            <td colSpan={3} className={styles.empty}>{inProgress ?
                                <div className={styles.loadingIcon}>
                                    <LoaderComponent/>
                                </div> : t('No users found')}
                            </td>
                        </tr>
                    }
                    </tbody>
                </table>
                {users ? <PagingComponent currentPage={page}
                                          initialItemsPerPage={itemsPerPage}
                                          onPreviousPage={() => onPreviousPage()}
                                          onNextPage={() => onNextPage()}
                                          onJumpToPage={(index: number) => onJumpToPage(index)}
                                          total={totalItems}
                                          onItemsPerPageChanged={(items: number) => onItemsPerPageChanged(items)}
                /> : null}
            </div>
        </div>
    )
}

export default UsersPage;
