import {ToastMessage} from "../../types/toast";

export enum LayoutActions {
    AddToast = 'LAYOUT/addToast',
    RemoveToast = 'LAYOUT/removeToast',
    ShowHeader = 'LAYOUT/showHeader',
    HideHeader = 'LAYOUT/hideHeader',
}

export type LayoutAction =
    | { type: LayoutActions.AddToast, toast: ToastMessage }
    | { type: LayoutActions.RemoveToast, toastId: string }
    | { type: LayoutActions.ShowHeader}
    | { type: LayoutActions.HideHeader}

