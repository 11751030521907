
// t('UpdatedOnAscending')
// t('UpdatedOnDescending')
// t('EmailAscending')
// t('EmailDescending')
// t('AddressAscending')
// t('AddressDescending')
// t('StatusAscending')
// t('StatusDescending')
export enum InvitationSortOrder {
    UpdatedOnAscending = 'UpdatedOnAscending',
    UpdatedOnDescending = 'UpdatedOnDescending',
    EmailAscending = 'EmailAscending',
    EmailDescending = 'EmailDescending',
    AddressAscending = 'AddressAscending',
    AddressDescending = 'AddressDescending',
    StatusAscending = 'StatusAscending',
    StatusDescending = 'StatusDescending',
}
