import React, {FunctionComponent, useContext} from 'react';
import styles from './app-header.component.module.scss';
import StadimLogo from '../../../assets/images/logo-stadim.svg';
import UserComponent from '../user/user.component';
import {AppContext} from '../../store/app-context';
import {useTranslation} from 'react-i18next';
import {useHistory, useLocation} from 'react-router-dom';
import {RouteUrl} from '../../routes';
import {UserRole} from '../../types/user';
import {conditionalClassLister} from '../../utils/class-helper';
import {BankActionTypes} from '../../store/bank/types';
import LanguageSelectorComponent, {LanguageSelectorLocation} from '../language-selector/language-selector.component';

const AppHeader: FunctionComponent = () => {
    const {t} = useTranslation();
    const {state, dispatch} = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();

    const navigateTo = (route: RouteUrl) => {
        if (state.bankState.bank && state.bankState.bank.id) {
            history.push(route.replace(':bankId', state.bankState.bank.id));
            if (route === RouteUrl.Root) {
                dispatch({type: BankActionTypes.SetBank, bank: undefined});
            }
        }
    }

    const menuItemStylesInvitation = conditionalClassLister(styles)({
        menuItem: true,
        selected: RegExp('.*/invitations').test(location.pathname)
    });

    const menuItemStylesUser = conditionalClassLister(styles)({
        menuItem: true,
        selected: RegExp('.*/users').test(location.pathname)
    });

    const menuItemStylesGuidelines = conditionalClassLister(styles)({
        menuItem: true,
        selected: RegExp('.*/guidelines').test(location.pathname)
    });

    return (
        <div className={styles.container}>
            <div className={styles.leftPanel}>
                <img className={styles.logo} alt="logo" src={StadimLogo} onClick={() => navigateTo(RouteUrl.Root)}/>
                <div className={styles.appName}>AVM</div>
                {location.pathname !== RouteUrl.Root ?
                    <div className={styles.menuItemContainer}>
                        <div className={menuItemStylesInvitation}
                             onClick={() => navigateTo(RouteUrl.Invitations)}>
                            {state.bankState.bank ? t('Invitations') : ''}
                        </div>
                        {state.userState.user?.role === UserRole.Admin ?
                            <div className={menuItemStylesUser}
                                 onClick={() => navigateTo(RouteUrl.Users)}>
                                {state.bankState.bank ? t('Users') : ''}
                            </div> : ''}
                        {state.bankState.bank?.hasGuidelines && <div className={menuItemStylesGuidelines}
                             onClick={() => navigateTo(RouteUrl.Guidelines)}>
                            {state.bankState.bank ? t('Guidelines') : '' }
                        </div>}
                    </div>
                : ''}
            </div>
            <div className={styles.rightPanel}>
                <LanguageSelectorComponent languageSelectorLocation={LanguageSelectorLocation.Footer}/>
                <UserComponent/>
            </div>
        </div>
    )
}

export default AppHeader;
