export interface Popup {
    content: JSX.Element;
    size: PopupSize;
}

export enum PopupSize {
    Small,
    Medium,
    Large,
}

export enum PopupActionTypes {
    ShowPopup = 'POPUP/ShowPopup',
    ClosePopup = 'POPUP/ClosePopup',
}

export type PopupAction =
    | { type: PopupActionTypes.ShowPopup, popup: Popup }
    | { type: PopupActionTypes.ClosePopup }

export interface PopupState {
    popup?: Popup;
}
