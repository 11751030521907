import React, { FunctionComponent, useEffect, useState } from 'react';
import styles from './configuration.module.scss';
import { useTranslation } from 'react-i18next';
import { VariableDisplay } from '../../types/variable-display';
import ConfigDropdownComponent from './config-dropdown.component';
import { WebformConfiguration } from '../../types/webform-configuration';
import { enumToSelectionOptions } from '../../components/dropdown/selection-option.util';

const WebformConfigurationComponent: FunctionComponent<OwnProps> = ({
	isNew = false,
	initialWebformConfiguration,
	onConfigurationChanged,
}: OwnProps) => {
	const { t } = useTranslation();
	const [editMode, setEditMode] = useState<boolean>(false);
	const [configuration, setConfiguration] = useState<WebformConfiguration>(
		initialWebformConfiguration
	);

	const variableDisplayOptions = enumToSelectionOptions(VariableDisplay, t);

	useEffect(() => {
		if (isNew) {
			setEditMode(true);
		}
	}, [isNew]);

	useEffect(() => {
		if (isNew) {
			onConfigurationChanged(configuration);
		}
	}, [configuration]);

	useEffect(() => {
		if (initialWebformConfiguration) {
			setConfiguration(initialWebformConfiguration);
		}
	}, [initialWebformConfiguration]);

	const onSave = () => {
		onConfigurationChanged(configuration);
		setEditMode(false);
	};

	const onCancel = () => {
		setConfiguration(initialWebformConfiguration);
		setEditMode(false);
	};

	const onDisplayStorageBasementSizeChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayStorageBasementSize = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayStorageAtticSizeChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayStorageAtticSize = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayBalconyAndTerraceSizeChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayBalconyAndTerraceSize = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayPrivateGardenSizeChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayPrivateGardenSize = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayCommunalGardenSizeChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayCommunalGardenSize = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayBuiltInFurnitureChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayBuiltInFurniture = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayPartialRenovationChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayPartialRenovation = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayOverallRenovationWithPermitChanged = (
		value: VariableDisplay
	) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayOverallRenovationWithPermit = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayTransactionPriceChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayTransactionPrice = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayTransactionDateChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayTransactionDate = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayLastValuationPriceChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayLastValuationPrice = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayLastValuationDateChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayLastValuationDate = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayPlansUploadChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayPlansUpload = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayEpcReportUploadChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayEpcReportUpload = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayAmenitiesImagesChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayAmenitiesImagesUpload = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayBuildingImagesUploadChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayBuildingImagesUpload = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayBuildingPermitUploadChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayBuildingPermitUpload = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayValuationReportUploadChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayValuationReportUpload = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayEpcChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayEpc = value;
		setConfiguration(configurationCopy);
	};

	return (
		<div className={styles.configurationPanel}>
			<div className={styles.panelTitle}>
				<div>{t('Webform Configuration')}</div>
				{!isNew && !editMode && (
					<div className={styles.buttons}>
						<button className={styles.button} onClick={() => setEditMode(true)}>
							{t('Edit')}
						</button>
					</div>
				)}
				{!isNew && editMode && (
					<div className={styles.buttons}>
						<button
							className={`${styles.button} ${styles.negative}`}
							onClick={() => onCancel()}
						>
							{t('Cancel')}
						</button>
						<button className={styles.button} onClick={() => onSave()}>
							{t('Save')}
						</button>
					</div>
				)}
			</div>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Storage basement size')}
				editMode={editMode}
				value={configuration.displayStorageBasementSize}
				onValueChanged={value => onDisplayStorageBasementSizeChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Storage attic size')}
				editMode={editMode}
				value={configuration.displayStorageAtticSize}
				onValueChanged={value => onDisplayStorageAtticSizeChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Balcony and terrace size')}
				editMode={editMode}
				value={configuration.displayBalconyAndTerraceSize}
				onValueChanged={value => onDisplayBalconyAndTerraceSizeChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Private garden size')}
				editMode={editMode}
				value={configuration.displayPrivateGardenSize}
				onValueChanged={value => onDisplayPrivateGardenSizeChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Communal garden')}
				editMode={editMode}
				value={configuration.displayCommunalGardenSize}
				onValueChanged={value => onDisplayCommunalGardenSizeChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Built-in furniture')}
				editMode={editMode}
				value={configuration.displayBuiltInFurniture}
				onValueChanged={value => onDisplayBuiltInFurnitureChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('EPC score / insulations')}
				editMode={editMode}
				value={configuration.displayEpc}
				onValueChanged={value => onDisplayEpcChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			{/*<ConfigDropdownComponent<VariableDisplay>*/}
			{/*	label={t('Partial renovation')}*/}
			{/*	editMode={editMode}*/}
			{/*	value={configuration.displayPartialRenovation}*/}
			{/*	onValueChanged={value => onDisplayPartialRenovationChanged(value)}*/}
			{/*	selectionOptions={variableDisplayOptions}*/}
			{/*/>*/}
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Overall renovation with permit')}
				editMode={editMode}
				value={configuration.displayOverallRenovationWithPermit}
				onValueChanged={value =>
					onDisplayOverallRenovationWithPermitChanged(value)
				}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Transaction price')}
				editMode={editMode}
				value={configuration.displayTransactionPrice}
				onValueChanged={value => onDisplayTransactionPriceChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Transaction date')}
				editMode={editMode}
				value={configuration.displayTransactionDate}
				onValueChanged={value => onDisplayTransactionDateChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Last valuation price')}
				editMode={editMode}
				value={configuration.displayLastValuationPrice}
				onValueChanged={value => onDisplayLastValuationPriceChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Last valuation date')}
				editMode={editMode}
				value={configuration.displayLastValuationDate}
				onValueChanged={value => onDisplayLastValuationDateChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Plans upload')}
				editMode={editMode}
				value={configuration.displayPlansUpload}
				onValueChanged={value => onDisplayPlansUploadChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Epc report upload')}
				editMode={editMode}
				value={configuration.displayEpcReportUpload}
				onValueChanged={value => onDisplayEpcReportUploadChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Amenities images upload')}
				editMode={editMode}
				value={configuration.displayAmenitiesImagesUpload}
				onValueChanged={value => onDisplayAmenitiesImagesChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Building images upload')}
				editMode={editMode}
				value={configuration.displayBuildingImagesUpload}
				onValueChanged={value => onDisplayBuildingImagesUploadChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Building permit upload')}
				editMode={editMode}
				value={configuration.displayBuildingPermitUpload}
				onValueChanged={value => onDisplayBuildingPermitUploadChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Valuation report upload')}
				editMode={editMode}
				value={configuration.displayValuationReportUpload}
				onValueChanged={value => onDisplayValuationReportUploadChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
		</div>
	);
};
export default WebformConfigurationComponent;

type OwnProps = {
	isNew?: boolean;
	initialWebformConfiguration: WebformConfiguration;
	onConfigurationChanged: (config: WebformConfiguration) => void;
};
