import React, { FunctionComponent } from "react";
import { VariableDisplay } from "../../types/variable-display";
import styles from "./input.module.scss";
import { conditionalClassLister } from "../../utils/class-helper";
import ReactTooltip from "react-tooltip";
import exclamationMarkIcon from "../../../assets/images/icon_Exclamation Mark.svg";
import { useTranslation } from "react-i18next";

const LabeledTextAreaComponent: FunctionComponent<OwnProps> = ({
  placeholder,
  id,
  label,
  display,
  errors,
  value,
  valueChanged,
  rows = 8
}: OwnProps) => {
  const { t } = useTranslation();

  const displayProperty = (propertyDisplay: VariableDisplay): boolean => {
    return (
      propertyDisplay === VariableDisplay.Required ||
      propertyDisplay === VariableDisplay.Optional
    );
  };

  const isOptional = (propertyDisplay: VariableDisplay): boolean => {
    return propertyDisplay === VariableDisplay.Optional;
  };

  return displayProperty(display) ? (
    <div className={styles.formField}>
      <div className={styles.labelContainer}>
        <label className={styles.label} htmlFor={id}>
          {label}
          {isOptional(display) && (
            <span className={styles.floatRight}>
              <small>{t("Optional")}</small>
            </span>
          )}
        </label>
      </div>
      <div className={styles.inputContainer}>
        <textarea
          className={styles.textareaInput}
          id={id}
          value={value}
          onChange={(e) => valueChanged(e.target.value)}
          placeholder={placeholder}
          rows={rows}
        />
        {errors ? (
          <div className={styles.invalid}>
            <ReactTooltip effect="solid" />
            <img src={exclamationMarkIcon} data-tip={errors} />
          </div>
        ) : null}
      </div>
    </div>
  ) : (
    <></>
  );
};
export default LabeledTextAreaComponent;

type OwnProps = {
  id: string;
  label: string;
  display: VariableDisplay;
  errors?: string;
  value: string;
  valueChanged: (value: string) => void;
  placeholder?: string;
  rows?: number
};
