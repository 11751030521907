import React, {createContext, Dispatch, FunctionComponent, useReducer} from 'react';
import {AppAction, AppState} from './types';
import {initialUserState, userReducer} from './user/app-context';
import {initialLayoutState, layoutReducer} from './layout/app-context';
import {bankReducer, initialBankState} from './bank/app-context';
import {errorReducer, initialErrorState} from './error/app-context';
import {initialInvitationState, invitationReducer} from './invitation/app-context';
import {initialPopupState, popupReducer} from "./popup/app-context";

const initialState: AppState = {
    userState: initialUserState,
    layoutState: initialLayoutState,
    bankState: initialBankState,
    errorState: initialErrorState,
    invitationState: initialInvitationState,
    popupState: initialPopupState,
};

export const AppContext = createContext<{
    state: AppState;
    dispatch: Dispatch<AppAction>;
}>({
    state: initialState,
    dispatch: () => null
});

const mainReducer = (
    {userState, layoutState, bankState, errorState, invitationState, popupState}: AppState,
    action: AppAction
) =>
    ({
        userState: userReducer(userState, action),
        layoutState: layoutReducer(layoutState, action),
        bankState: bankReducer(bankState, action),
        errorState: errorReducer(errorState, action),
        invitationState: invitationReducer(invitationState, action),
        popupState: popupReducer(popupState, action),
    });

const AppProvider: FunctionComponent = ({children}) => {
    const [state, dispatch] = useReducer(mainReducer, initialState);
    return (
        <AppContext.Provider value={{state, dispatch}}>
            {children}
        </AppContext.Provider>
    );
};

export default AppProvider;
