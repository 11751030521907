export interface ErrorContract {
    type: ServerErrorType;
    message: string;
    correlationID: string;
}

export enum ServerErrorType {
    General = 'General',
    Unauthorized = 'Unauthorized',
    NotFound = 'NotFound',
    Conflict = 'Conflict',

    UserNotFound = 'UserNotFound',
    UserNotLinked = 'UserNotLinked',
    UserPermission = 'UserPermission',
}

export const userErrors = [
    ServerErrorType.UserNotFound,
    ServerErrorType.UserNotLinked,
    ServerErrorType.UserPermission,
]
