import * as React from 'react';
import {FunctionComponent, useContext} from 'react';
import styles from './confirm-delete-popup.module.scss';
import {useTranslation} from "react-i18next";
import {AppContext} from "../../store/app-context";
import {PopupActionTypes} from "../../store/popup/types";

const ConfirmationPopupComponent: FunctionComponent<OwnProps> = ({callback, message, title}: OwnProps) => {
    const {dispatch} = useContext(AppContext);
    const {t} = useTranslation();

    const onCancel = () => {
        dispatch({
            type: PopupActionTypes.ClosePopup,
        });
    }

    const onConfirm = () => {
        callback();
    }

    return (
        <div className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.text}>{message}</div>
            <div className={styles.buttonsContainer}>
                <button className={styles.button} onClick={() => onConfirm()}>{t('Confirm')}</button>
                <button className={`${styles.button} ${styles.negative} `} onClick={() => onCancel()}>{t('Cancel')}</button>
            </div>
        </div>
    );
}

export default ConfirmationPopupComponent;

export interface OwnProps {
    title: string;
    message: string;
    callback: () => void;
}
