import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import styles from './guidelines.module.scss';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {RouteUrl} from '../../routes';
import {AdminClientParams} from '../../types/admin-client-params';
import {AppContext} from '../../store/app-context';
import {BankActionTypes} from '../../store/bank/types';
import { Document, Page, pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import {PDFDocumentProxy} from 'pdfjs-dist/types/src/display/api';
import {ReactComponent as NextComponent} from '../../../assets/images/chevron-right-solid.svg';
import {ReactComponent as PreviousComponent} from '../../../assets/images/chevron-left-solid.svg';
import {ReactComponent as ZoomInComponent} from '../../../assets/images/magnifying-glass-plus-solid.svg';
import {ReactComponent as ZoomOutComponent} from '../../../assets/images/magnifying-glass-minus-solid.svg';
import {ReactComponent as DownloadComponent} from '../../../assets/images/download.svg';
import TitleWithBack from '../../components/title-with-back/title-with-back.component';
import BankApi from '../../api/bank-api';
import {saveAs} from 'file-saver';
import {conditionalClassLister} from '../../utils/class-helper';


const GuidelinesPage: FunctionComponent = () => {
    const {state, dispatch} = useContext(AppContext);
    const bank = state.bankState.bank;
    const {t} = useTranslation();
    const history = useHistory();
    const {bankId} = useParams<AdminClientParams>();
    const [availableFiles, setAvailableFiles] = useState<FileNamesPerLanguage[]>([]);
    const [file, setFile] = useState<File>();
    const [numberOfPages, setNumberOfPages] = useState<number>();
    const [currentPage, setCurrentPage] = useState<number>();
    const [scale, setScale] = useState<number>(1.0);
    const [activeFileKey, setActiveFileKey] = useState<string>();

    const options = {
        standardFontDataUrl: 'standard_fonts/',
    };

    useEffect(() => {
        if (!bankId || !bank) {
            history.push(RouteUrl.Root);
        } else {
            BankApi.getFileNames(bankId).then((fileNames) => {
                setAvailableFiles(fileNames);
            });
        }
    }, [bankId]);

    const getFile = (fileName: string, language: string, fileKey: string) => {
        if (activeFileKey !== fileKey) {
            BankApi.getFile(bankId, fileName, language).then(resp => {
                setActiveFileKey(fileKey);
                setFile(new File([resp], fileName));
                setCurrentPage(1);
                setScale(1.0);
            });
        }
    }

    const navigateTo = (route: RouteUrl) => {
        if (route === RouteUrl.Root) {
            dispatch({type: BankActionTypes.SetBank, bank: undefined});
        }
        history.push(route.replace(':bankId', bankId));
    }

    const onDocumentLoadSuccess = (pdf: PDFDocumentProxy) => {
        setNumberOfPages(pdf.numPages);
        if (pdf.numPages > 0) {
            setCurrentPage(1);
        }
    }

    const previousPage = () => {
        if (currentPage && currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    }

    const nextPage = () => {
        if (numberOfPages && currentPage && currentPage < numberOfPages) {
            setCurrentPage(currentPage + 1);
        }
    }

    const increaseScale = () => {
        setScale(scale + 0.1);
    }

    const decreaseScale = () => {
        setScale(scale - 0.1);
    }

    const downloadFile = () => {
        if (file) {
            saveAs(file);
        }
    }

    const getFileName = (fileName: string): string => {
        return fileName.replace('.pdf', '');
    }

    return bank ? (
        <div className={styles.page}>
            <div className={styles.content}>
                <TitleWithBack title={t('Guidelines')} onBackClick={() => {navigateTo(RouteUrl.Invitations)}}/>

                {availableFiles && <div className={styles.filesContainer}>{availableFiles
                    .filter(files => files.fileNames && files.fileNames.length > 0)
                    .map((files) => (
                    <div className={styles.languageGroup} key={files.language}>
                        <div className={styles.language}>{files.language}</div>
                        <div className={styles.buttons}>{files.fileNames.map((fileName, index) => (
                            <div key={`${files.language}_${index}`}
                                 className={conditionalClassLister(styles)({
                                     button: true,
                                     active: activeFileKey === `${files.language}_${index}`
                                 })}
                                 onClick={() => getFile(fileName, files.language, `${files.language}_${index}`)}>{getFileName(fileName)}</div>)
                        )}</div>
                    </div>
                ))}</div>}


                {file && <div className={styles.panel}>
                    <div className={styles.pdfContainer}>
                        <Document file={file}
                                  className={styles.document}
                                  onLoadSuccess={onDocumentLoadSuccess}
                                  options={options}>
                            {currentPage && <Page pageNumber={currentPage}
                                                  height={600}
                                                  scale={scale}
                                                  renderAnnotationLayer={false}
                                                  renderTextLayer={false}/>}
                        </Document>
                    </div>
                    <div className={styles.pdfControls}>
                        <div className={styles.downloadControls}>
                            <DownloadComponent className={styles.pdfControl} onClick={downloadFile}/>
                        </div>
                        <div className={styles.pageControls}>
                            <PreviousComponent className={styles.pdfControl} onClick={previousPage}/>
                            <div className={styles.currentPage} >{currentPage} of {numberOfPages}</div>
                            <NextComponent className={styles.pdfControl} onClick={nextPage}/>
                        </div>
                        <div className={styles.zoomControls}>
                            <ZoomInComponent className={styles.pdfControl} onClick={increaseScale}/>
                            <ZoomOutComponent className={styles.pdfControl} onClick={decreaseScale}/>
                        </div>
                    </div>
                </div>}

                <div className={`${styles.buttons} ${styles.alignLeft}`}>
                    <button className={`${styles.button} ${styles.negative}`} onClick={() => navigateTo(RouteUrl.Invitations)}>{t('Back')}</button>
                </div>
            </div>
        </div>
    ) : null;
}

export default GuidelinesPage;

export type FileNamesPerLanguage = {
    language: string,
    fileNames: string[],
}
