
export interface UsersRequest {
    sorting?: SortingOptions;
    page: number;
    itemsPerPage: number;
    filter?: string;
}

export interface SortingOptions {
    sortType: SortType;
    orderBy: Order;
}

export enum Order {
    Ascending = 'ASC',
    Descending = 'DESC'
}

export enum SortType {
    CreatedOn = 'createdOn',
    Email = 'email',
    Role = 'role',
}
