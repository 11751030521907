
export interface Cluster {
    id: string;
    name: string;
}

export const Clusters: Cluster[] = [
    {
        id: '220749',
        name: 'Regio Turnhout-Hoogstraten',
    },
    {
        id: '221119',
        name: 'Regio Mortsel-Kontich',
    },
    {
        id: '221218',
        name: 'Antwerpen Zuid',
    },
    {
        id: '221473',
        name: 'Vilvoorde-Tervuren',
    },
    {
        id: '221671',
        name: 'Netevallei',
    },
    {
        id: '222117',
        name: 'Rivierenland',
    },
    {
        id: '222133',
        name: 'Regio Noord-Antwerpen Noorderkempen',
    },
    {
        id: '241364',
        name: 'Zone Brabant Wallon-Charleroi-Namur',
    },
    {
        id: '241489',
        name: 'Zone Liege-Verviers',
    },
    {
        id: '242792',
        name: 'Zone Ostbelgien',
    },
    {
        id: '242503',
        name: 'Zone Bruxelles',
    },
    {
        id: '220293',
        name: 'Brussels East',
    },
    {
        id: '220509',
        name: 'Tournai – Val De Verne',
    },
    {
        id: '220517',
        name: 'Charleroi Metropole',
    },
    {
        id: '221523',
        name: 'Auderghem-Boitsfort',
    },
    {
        id: '221564',
        name: 'Hainaut Sud-Ouest',
    },
    {
        id: '221655',
        name: 'Foret De Soignes',
    },
    {
        id: '221952',
        name: 'Sille Et Dendre',
    },
    {
        id: '220244',
        name: 'Basse-Meuse Et Bocage',
    },
    {
        id: '220459',
        name: 'Liege Cite Ardente',
    },
    {
        id: '220665',
        name: 'Liege Ardenne-Condroz',
    },
    {
        id: '221366',
        name: 'Sud-Luxembourg',
    },
    {
        id: '221762',
        name: 'Condroz-Famenne',
    },
];
