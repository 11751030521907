import React, {FunctionComponent, Suspense, useContext, useState} from 'react';
import {i18n} from 'i18next';
import {MsalAuthenticationTemplate} from '@azure/msal-react';
import {InteractionType} from '@azure/msal-browser';
import AppProvider, {AppContext} from './store/app-context';
import ToastMessagesComponent from './components/toast-messages/toast-messages.component';
import {RuntimeConfig} from '../config/runtime-config';
import Main from './main';
import PopupContainerComponent from "./components/popup-container/popup-container.component";


export const App: FunctionComponent<OwnProps> = () => {
    const {state, dispatch} = useContext(AppContext);
    RuntimeConfig.getApiURL();
    return (
        <AppProvider>
            <PopupContainerComponent/>
            <ToastMessagesComponent/>
            <Suspense fallback="">
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}>
                    <Main/>
                </MsalAuthenticationTemplate>
            </Suspense>
        </AppProvider>
    )
}

export default App;

interface OwnProps {
    i18n: i18n;
}
