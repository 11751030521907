import * as React from 'react';
import {FunctionComponent, useContext, useEffect, useState} from 'react';
import styles from './popup-container.module.scss';
import {conditionalClassLister} from "../../utils/class-helper";
import {Popup, PopupActionTypes, PopupSize} from "../../store/popup/types";
import {AppContext} from "../../store/app-context";
import {ReactComponent as CloseIconComponent} from "../../../assets/images/close.svg";

const PopupContainerComponent: FunctionComponent = () => {
    const [popup, setPopup] = useState<Popup | undefined>(undefined)
    const {state, dispatch} = useContext(AppContext);
    let popupContainerClasses = undefined;

    useEffect(() => {
        setPopup(state.popupState.popup);
    }, [state.popupState.popup]);

    if (popup) {
        popupContainerClasses = conditionalClassLister(styles)({
            popupContainer: true,
            popupContainerSmall: popup.size === PopupSize.Small,
            popupContainerMedium: popup.size === PopupSize.Medium,
            popupContainerLarge: popup.size === PopupSize.Large,
        });
    }

    const closePopup = () => {
        dispatch({
            type: PopupActionTypes.ClosePopup,
        })
    }

    return (
        <div>
            {popup ?
                <div className={styles.container}>
                    <div className={styles.overlay}/>
                    <div className={popupContainerClasses}>
                        <CloseIconComponent className={styles.close} onClick={() => closePopup()}></CloseIconComponent>
                        {popup.content}
                    </div>
                </div>
                : ''
            }
        </div>

    );
}

export default PopupContainerComponent;

export interface OwnProps {
    popup?: Popup;
}

