import {ToastMessage} from "../../types/toast";
import {LayoutActions} from "./types";
import {AppAction} from "../types";

export type LayoutState = {
    toasts: ToastMessage[];
    showHeader: boolean;
};

export const initialLayoutState: LayoutState = {
    toasts: [],
    showHeader: false,
};

export const layoutReducer = (state: LayoutState, action: AppAction): LayoutState => {
    switch (action.type) {
        case LayoutActions.AddToast:
            return {...state, toasts: [action.toast, ...state.toasts]};
        case LayoutActions.RemoveToast:
            return {...state, toasts: state.toasts.filter(toast => toast.id !== action.toastId)};
        case LayoutActions.ShowHeader:
            return {...state, showHeader: true};
        case LayoutActions.HideHeader:
            return {...state, showHeader: false};
        default:
            return state;
    }
};
