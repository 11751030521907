import React, {FormEvent, FunctionComponent, useContext, useEffect, useState,} from 'react';
import styles from './create-invitation.module.scss';
import {useTranslation} from 'react-i18next';
import {Regex} from '../../types/regex';
import {AppContext} from '../../store/app-context';
import BankApi from '../../api/bank-api';
import {LayoutActions} from '../../store/layout/types';
import {ToastMessage, ToastMessageType} from '../../types/toast';
import {SelectionOption} from '../../components/dropdown/dropdown.component';
import {useHistory, useParams} from 'react-router-dom';
import {RouteUrl} from '../../routes';
import {AdminClientParams} from '../../types/admin-client-params';
import LoaderComponent from '../../components/loader/loader';
import {enumToSelectionOptions} from '../../components/dropdown/selection-option.util';
import {InvitationRequest, LoanPurpose, TransactionType,} from '../../types/request/invitation-request';
import TitleWithBack from '../../components/title-with-back/title-with-back.component';
import {Bank, PaymentStrategy} from '../../types/bank';
import {InvitationConfiguration} from '../../types/invitation-configuration';
import {VariableDisplay} from '../../types/variable-display';
import LabeledInputComponent from '../../components/input/labeled-input.component';
import LabeledDropdownComponent from '../../components/input/labeled-dropdown.component';
import LabeledNumberInputComponent from '../../components/input/labeled-number-input.component';
import {buildWebformUrl} from '../../utils/webform-url-builder';
import {Clusters} from '../../types/cluster';
import {BelfiusBankId, VanBredaBankId} from '../../constants/bank-ids';
import {RealEstateType} from '../../types/invitation';
import LabeledTextAreaComponent from '../../components/input/labeled-textarea.component';

const CreateInvitationPage: FunctionComponent = () => {
	const [realEstateType, setRealEstateType] = useState<RealEstateType>(RealEstateType.RRE)
	const [firstName, setFirstName] = useState<string>('');
	const [lastName, setLastName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [secondaryEmail, setSecondaryEmail] = useState<string>('');
	const [address, setAddress] = useState<string>('');
	const [reference, setReference] = useState<string>('');
	const [language, setLanguage] = useState<string>('');
	const [fullVisit, setFullVisit] = useState<string>('');
	const [fullPropertyRights, setFullPropertyRights] = useState<string>('');
	const [valuationNeeded, setValuationNeeded] = useState<string>('yes');
	const [loanPurpose, setLoanPurpose] = useState<LoanPurpose | undefined>();
	const [transactionValue, setTransactionValue] = useState<string | undefined>(
		''
	);
	const [transactionType, setTransactionType] = useState<TransactionType | undefined>();
	const [transactionYear, setTransactionYear] = useState<string | undefined>(
		''
	);
	const [renovationBudget, setRenovationBudget] = useState<string | undefined>(
		''
	);
	const [nsxId, setNsxId] = useState<string>('');
	const [cluster, setCluster] = useState<string>('');
	const [errors, setErrors] = useState<InvitationErrors>({});
	const [inProgress, setInProgress] = useState<boolean>(false);
	const { dispatch, state } = useContext(AppContext);
	const [bank] = useState<Bank | undefined>(state.bankState.bank);
	const [configuration, setConfiguration] = useState<InvitationConfiguration>();
	const [paymentStrategyOptions, setPaymentStrategyOptions] = useState<
		SelectionOption[]
	>([]);
	const [paymentStrategy, setPaymentStrategy] = useState<
		PaymentStrategy | undefined
	>();
	const [extraInfo, setExtraInfo] = useState<string>('')
	const { t, i18n } = useTranslation();
	const history = useHistory();
	const { bankId } = useParams<AdminClientParams>();
	const mailRegex = new RegExp(Regex.Email);
	const [webformUrl, setWebformUrl] = useState<string>();

	const realEstateTypeOptions: SelectionOption[] = enumToSelectionOptions(RealEstateType, t);

	const languageOptions: SelectionOption[] = [
		{ key: 'nl', value: 'nl', label: 'Nederlands' },
		{ key: 'en', value: 'en', label: 'English' },
		{ key: 'fr', value: 'fr', label: 'Français' },
	];

	const fullVisitOptions: SelectionOption[] = [
		{ key: 'yes', value: 'yes', label: t('Yes: Full visit') },
		{ key: 'no', value: 'no', label: t('No: Desktop') },
	];

	const fullPropertyRightsOptions: SelectionOption[] = [
		{ key: 'yes', value: 'yes', label: t('Yes') },
		{ key: 'no', value: 'no', label: t('No') },
	];

	const valuationNeededOptions = [
		{ key: 'yes', value: 'yes', label: t('Yes') },
		// {key: 'no', value: 'no', label: t('No')},
	];

	const loanOptions: SelectionOption[] = enumToSelectionOptions(LoanPurpose, t);
	const transactionTypeOptions: SelectionOption[] = enumToSelectionOptions(TransactionType, t);

	const clusterOptions: SelectionOption[] = Clusters.sort((a, b) =>
		a.id.localeCompare(b.id)
	).map(c => {
		return {
			key: c.id,
			label: `${c.id} (${c.name})`,
			value: c.id,
		};
	});

	useEffect(() => {
		if (!bankId || !bank) {
			history.push(RouteUrl.Root);
		} else {
			if (bank.paymentStrategies.length === 1) {
				setPaymentStrategy(bank.paymentStrategies[0]);
			} else if (
				state.bankState.invitationConfiguration?.defaultPaymentStrategy
			) {
				setPaymentStrategy(
					state.bankState.invitationConfiguration.defaultPaymentStrategy
				);
			}
			const options = bank.paymentStrategies.map(strategy => ({
				key: strategy,
				value: strategy,
				label: t(strategy),
			}));
			setFullPropertyRights('yes');
			setPaymentStrategyOptions(options);
		}
	}, [bankId, bank]);

	useEffect(() => {
		if (state.bankState.invitationConfiguration) {
			setConfiguration(state.bankState.invitationConfiguration);
		}
	}, [state.bankState.invitationConfiguration]);

	useEffect(() => {
		const selectedLanguage = languageOptions.find(
			option => option.key === i18n.language
		);
		setLanguage(
			selectedLanguage && selectedLanguage.key ? selectedLanguage.key : 'en'
		);
	}, []);

	useEffect(() => {
		if (loanPurpose === LoanPurpose.Purchase) {
			setRenovationBudget('');
		}
	}, [loanPurpose]);

	const handleFormSubmit = (e: FormEvent) => {
		e.preventDefault();
		let isValid = true;
		if (
			configuration?.displayReference === VariableDisplay.Required &&
			!reference
		) {
			isValid = false;
			setErrors(prevValue =>
				setReferenceError(t('Reference is required'), prevValue)
			);
		}
		if (
			bankId === BelfiusBankId &&
			configuration?.displayReference === VariableDisplay.Required &&
			reference &&
			(reference.length !== 7 || !(reference.startsWith('24') || reference.startsWith('25')))
		) {
			isValid = false;
			setErrors(prevValue =>
				setReferenceError(t('Invalid reference'), prevValue)
			);
		}
		if(configuration?.displayAddress === VariableDisplay.Required && !address) {
			isValid = false
			setErrors(prevValue => setAddressError(t('Address required'), prevValue))
		}
		if (
			configuration?.displayEmail === VariableDisplay.Required &&
			(!email || !mailRegex.test(email))
		) {
			isValid = false;
			setErrors(prevValue => setEmailError(t('Invalid email'), prevValue));
		}
		if (
			configuration?.displayEmail === VariableDisplay.Required &&
			!firstName
		) {
			isValid = false;
			setErrors(prevValue =>
				setFirstNameError(t('First name is required'), prevValue)
			);
		}
		if (configuration?.displayEmail === VariableDisplay.Required && !lastName) {
			isValid = false;
			setErrors(prevValue =>
				setLastNameError(t('Last name is required'), prevValue)
			);
		}
		if (
			realEstateType === RealEstateType.RRE &&
			configuration?.displayFullVisitValuation === VariableDisplay.Required &&
			!fullVisit
		) {
			isValid = false;
			setErrors(prevValue =>
				setFullVisitError(
					t('Full visit valuation indicator is required'),
					prevValue
				)
			);
		}
		if (
			configuration?.displayLoanPurpose === VariableDisplay.Required &&
			!loanPurpose
		) {
			isValid = false;
			setErrors(prevValue =>
				setLoanPurposeError(t('Loan Purpose is required'), prevValue)
			);
		}
		if (
			realEstateType === RealEstateType.RRE &&
			configuration?.displayPaymentStrategy === VariableDisplay.Required &&
			!paymentStrategy
		) {
			isValid = false;
			setErrors(prevValue =>
				setPaymentStrategyError(t('Payment Strategy is required'), prevValue)
			);
		}
		if (configuration?.displayTransactionValue === VariableDisplay.Required) {
			const formattedTransaction = transactionValue
				? transactionValue.replace(/\s/g, '')
				: '';
			if (
				!formattedTransaction ||
				isNaN(parseInt(formattedTransaction, 10)) ||
				parseInt(formattedTransaction, 10) < 0
			) {
				isValid = false;
				setErrors(prevValue =>
					setTransactionValueError(t('Invalid transaction value'), prevValue)
				);
			}
		}
        if (configuration?.displayTransactionType === VariableDisplay.Required && !transactionType) {
            isValid = false;
            setErrors(prevValue => setTransactionTypeError(t('Transaction Type is required'), prevValue));
        }

		if (configuration?.displayTransactionYear === VariableDisplay.Required) {
			const formattedTransactionYear = transactionYear
				? transactionYear.replace(/\s/g, '')
				: '';
			if (
				!formattedTransactionYear ||
				isNaN(parseInt(formattedTransactionYear, 10)) ||
				parseInt(formattedTransactionYear, 10) < 1900
			) {
				isValid = false;
				setErrors(prevValue =>
					setTransactionYearError(t('Invalid transaction year'), prevValue)
				);
			}
		}

		if (configuration?.displayRenovationBudget === VariableDisplay.Required) {
			const formattedBudget = renovationBudget
				? renovationBudget.replace(/\s/g, '')
				: '';
			if (
				formattedBudget &&
				(isNaN(parseInt(formattedBudget, 10)) ||
					parseInt(formattedBudget, 10) < 0)
			) {
				isValid = false;
				setErrors(prevValue =>
					setRenovationBudgetError(t('Invalid renovation budget'), prevValue)
				);
			}
		}
		if (
			configuration?.displayFullPropertyRights === VariableDisplay.Required &&
			!fullPropertyRights
		) {
			isValid = false;
			setErrors(prevValue =>
				setFullPropertyRightsError(
					t('Full property rights indicator is required'),
					prevValue
				)
			);
		}
		if (!valuationNeeded) {
			isValid = false;
			setErrors(prevValue =>
				setValuationNeededError(
					t('Valuation needed indicator is required'),
					prevValue
				)
			);
		}
		if (configuration?.displayNsxId === VariableDisplay.Required && !nsxId) {
			isValid = false;
			setErrors(prevValue => setNsxIdError(t('NSX id is required'), prevValue));
		}
		if (
			configuration?.displayReference === VariableDisplay.Required &&
			!reference
		) {
			isValid = false;
			setErrors(prevValue =>
				setReferenceError(t('Reference is required'), prevValue)
			);
		}
		if (
			configuration?.displayCluster === VariableDisplay.Required &&
			!cluster
		) {
			isValid = false;
			setErrors(prevValue =>
				setClusterError(t('Cluster/zone is required'), prevValue)
			);
		}
		if (isValid) {
			setErrors({});
			if (!bankId || bankId === 'undefined') {
				dispatch({
					type: LayoutActions.AddToast,
					toast: new ToastMessage(
						ToastMessageType.Error,
						t('Could not find the correct bank. Please refresh the page and try again.')
					),
				});
			} else if (bankId) {
				setWebformUrl('');
				submitInvitationForm(bankId);
			} else {
				dispatch({
					type: LayoutActions.AddToast,
					toast: new ToastMessage(
						ToastMessageType.Error,
						t('Something went wrong, please try again.')
					),
				});
			}
		}
	};

	const submitInvitationForm = async (bankId: string) => {
		const selectedCluster = Clusters.find(c => c.id === cluster);
		setInProgress(true);
		const invitationRequest: InvitationRequest = {
			firstName,
			lastName,
			email,
			secondaryEmail,
			address,
			reference,
			language,
			fullVisitValuation: fullVisit === 'yes',
			loanPurpose: loanPurpose!,
			transactionValue: parseInt(transactionValue!.replace(/\s/g, ''), 10),
            transactionType,
			transactionYear: parseInt(transactionYear!.replace(/\s/g, ''), 10),
			renovationBudget: renovationBudget
				? parseInt(renovationBudget.replace(/\s/g, ''), 10)
				: undefined,
			accountManager: state.userState.user!.username,
			paymentStrategy: paymentStrategy!,
			fullPropertyRights: fullPropertyRights === 'yes',
			valuationNeeded: valuationNeeded === 'yes',
			nsxId,
			clusterId: selectedCluster ? selectedCluster.id : undefined,
			clusterName: selectedCluster ? selectedCluster.name : undefined,
			realEstateType,
			extraInformation: extraInfo
		};
		await BankApi.addInvitationToBank(bankId, invitationRequest)
			.then(response => {
				if (
					configuration &&
					configuration.displayEmail === VariableDisplay.Hidden
				) {
					const url = buildWebformUrl(bankId, response.token);
					setWebformUrl(url);
				} else {
					setFirstName('');
					setLastName('');
					setEmail('');
					setSecondaryEmail('');
					setAddress('');
					setReference('');
					setFullVisit('');
					setLoanPurpose(undefined);
					setTransactionValue('');
                    setTransactionType(undefined);
					setTransactionYear('');
					setRenovationBudget('');
					bank?.paymentStrategies.length === 1
						? setPaymentStrategy(bank?.paymentStrategies[0])
						: setPaymentStrategy(undefined);
					setValuationNeeded('yes');
					setNsxId('');
					setRealEstateType(RealEstateType.RRE);
					setExtraInfo('')
				}
				setErrors({});
				dispatch({
					type: LayoutActions.AddToast,
					toast: new ToastMessage(
						ToastMessageType.Success,
						t('Invitation successfully created')
					),
				});
			})
			.catch(err => {
				dispatch({
					type: LayoutActions.AddToast,
					toast: new ToastMessage(
						ToastMessageType.Error,
						t('Something went wrong, please try again.')
					),
				});
			});
		setInProgress(false);
	};

	const goToWebform = (e: FormEvent) => {
		e.preventDefault();
		if (webformUrl) {
			window.open(webformUrl, '_blank');
		}
	};

	const navigateTo = (route: RouteUrl) => {
		history.push(route.replace(':bankId', bankId));
	};

	const realEstateTypeChanged = (optionValue: string) => {
		const realEstateType = RealEstateType[optionValue as keyof typeof RealEstateType]
		setRealEstateType(realEstateType);
		if (realEstateType === RealEstateType.CRE) {
			setPaymentStrategy(PaymentStrategy.BankPayment);
		} else if (realEstateType === RealEstateType.RRE && bank && configuration) {
			if (bank.paymentStrategies.length === 1) {
				setPaymentStrategy(bank.paymentStrategies[0]);
			} else if (state.bankState.invitationConfiguration?.defaultPaymentStrategy) {
				setPaymentStrategy(configuration.defaultPaymentStrategy);
			} else {
				setPaymentStrategy(undefined);
			}
		}
	};

	const firstNameChanged = (value: string) => {
		setFirstName(value);
		setErrors(prevValue => setFirstNameError(undefined, prevValue));
	};

	const lastNameChanged = (value: string) => {
		setLastName(value);
		setErrors(prevValue => setLastNameError(undefined, prevValue));
	};

	const emailChanged = (value: string) => {
		setEmail(value);
		setErrors(prevValue => setEmailError(undefined, prevValue));
	};

	const secondaryEmailChanged = (value: string) => {
		setSecondaryEmail(value);
		setErrors(prevValue => setSecondaryEmailError(undefined, prevValue));
	};

	const fullVisitChanged = (value: string) => {
		setFullVisit(value);
		setErrors(prevValue => setFullVisitError(undefined, prevValue));
	};

	const loanPurposeChanged = (optionValue: string) => {
		setLoanPurpose(LoanPurpose[optionValue as keyof typeof LoanPurpose]);
		setErrors(prevValue => setLoanPurposeError(undefined, prevValue));
	};

	const paymentStrategyChanged = (optionValue: string) => {
		setPaymentStrategy(
			PaymentStrategy[optionValue as keyof typeof PaymentStrategy]
		);
		setErrors(prevValue => setPaymentStrategyError(undefined, prevValue));
	};

	const transactionValueChanged = (value: string) => {
		setTransactionValue(value);
		setErrors(prevValue => setTransactionValueError(undefined, prevValue));
	};

    const transactionTypeChanged = (optionValue: string) => {
        setTransactionType(TransactionType[optionValue as keyof typeof TransactionType]);
        setErrors(prevValue => setTransactionTypeError(undefined, prevValue));
    };

    const transactionYearChanged = (value: string) => {
		setTransactionYear(value);
		setErrors(prevValue => setTransactionYearError(undefined, prevValue));
	};

	const renovationBudgetChanged = (value: string) => {
		setRenovationBudget(value);
		setErrors(prevValue => setRenovationBudgetError(undefined, prevValue));
	};

	const fullPropertyRightsChanged = (value: string) => {
		setFullPropertyRights(value);
		setErrors(prevValue => setFullPropertyRightsError(undefined, prevValue));
	};

	const valuationNeededChanged = (value: string) => {
		setValuationNeeded(value);
		setErrors(prevValue => setValuationNeededError(undefined, prevValue));
		if (
			value === 'no' &&
			bank?.paymentStrategies.includes(PaymentStrategy.BankPayment)
		) {
			setPaymentStrategy(PaymentStrategy.BankPayment);
		}
	};

	const nsxIdChanged = (value: string) => {
		setNsxId(value);
		setErrors(prevValue => setNsxIdError(undefined, prevValue));
	};

	const clusterChanged = (value: string) => {
		setCluster(value);
		setErrors(prevValue => setClusterError(undefined, prevValue));
	};

	return configuration ? (
		<div className={styles.page}>
			<div className={styles.content}>
				<div className={styles.panel}>
					<TitleWithBack
						title={t('Create Invite')}
						onBackClick={() => {
							navigateTo(RouteUrl.Invitations);
						}}
					/>
					<form>
						{configuration.displayWarning && (
							<div className={styles.warning}>
								<p className={styles.warningText}>
									{t('RRE = Residential Real Estate = A singular residential property (one apartment or one house)')}
								</p>
								<p className={styles.warningText}>
									{t('CRE = Commercial Real Estate = Commercial, mixed or multiple residential real estate')}
								</p>
							</div>
						)}
						<div className={styles.formInputFields}>
							{configuration.displayRealEstateType !== VariableDisplay.Hidden && (
								<div className={styles.formGroup}>
									<LabeledDropdownComponent
										id="realEstateType"
										label={t('Real estate type')}
										display={configuration.displayRealEstateType}
										options={realEstateTypeOptions}
										value={realEstateType}
										valueChanged={realEstateTypeChanged}
										errors={errors.language}
									/>
								</div>
							)}
							{configuration.displayEmail !== VariableDisplay.Hidden && (
								<div className={styles.formGroup}>
									<LabeledInputComponent
										id="firstName"
										label={t('First Name')}
										display={VariableDisplay.Required}
										value={firstName}
										valueChanged={firstNameChanged}
										errors={errors.firstName}
									/>
									<LabeledInputComponent
										id="lastName"
										label={t('Last Name')}
										display={VariableDisplay.Required}
										value={lastName}
										valueChanged={lastNameChanged}
										errors={errors.lastName}
									/>
									<LabeledInputComponent
										id="email"
										label={t('Email')}
										display={configuration.displayEmail}
										value={email}
										valueChanged={emailChanged}
										errors={errors.email}
									/>
									<LabeledInputComponent
										id="secondaryEmail"
										label={t('Secondary email')}
										display={configuration.displaySecondaryEmail}
										value={secondaryEmail}
										valueChanged={secondaryEmailChanged}
										errors={errors.secondaryEmail}
									/>
									<LabeledDropdownComponent
										id="language"
										label={t('Language')}
										display={VariableDisplay.Required}
										options={languageOptions}
										value={language}
										valueChanged={setLanguage}
										errors={errors.language}
									/>
								</div>
							)}
							<div className={styles.formGroup}>
								<LabeledInputComponent
									id="address"
									label={t('Address of the property')}
									display={configuration.displayAddress}
									value={address}
									valueChanged={setAddress}
									errors={errors.address}
								/>
								<LabeledInputComponent
									id="reference"
									label={
										bankId === VanBredaBankId ? t('KA number') : t('Reference')
									}
									display={configuration.displayReference}
									value={reference}
									valueChanged={setReference}
									errors={errors.reference}
								/>
								<LabeledDropdownComponent
									id="cluster"
									label={t('Cluster/Zone')}
									display={configuration.displayCluster}
									options={clusterOptions}
									value={cluster}
									valueChanged={clusterChanged}
									errors={errors.cluster}
								/>
								<LabeledInputComponent
									id="nsxId"
									label={t('NSX Id')}
									display={configuration.displayNsxId}
									value={nsxId}
									valueChanged={nsxIdChanged}
									errors={errors.nsxId}
								/>
								{realEstateType === RealEstateType.RRE && (
									<LabeledDropdownComponent
										id="fullVisitValuation"
										label={t('Full visit valuation')}
										display={configuration.displayFullVisitValuation}
										options={fullVisitOptions}
										value={fullVisit}
										valueChanged={fullVisitChanged}
										errors={errors.fullVisit}
									/>
								)}
								<LabeledDropdownComponent
									id="fullPropertyRights"
									label={t('Full property rights')}
									display={configuration.displayFullPropertyRights}
									options={fullPropertyRightsOptions}
									value={fullPropertyRights}
									valueChanged={fullPropertyRightsChanged}
									errors={errors.fullPropertyRights}
									footnote={t(
										'Choose no in case of: usufruct, right of superficies, long lease or consession'
									)}
								/>
								<LabeledDropdownComponent
									id="loanPurpose"
									label={t('Purpose of the loan')}
									display={configuration.displayLoanPurpose}
									options={loanOptions}
									value={loanPurpose}
									valueChanged={loanPurposeChanged}
									errors={errors.loanPurpose}
								/>
								<LabeledNumberInputComponent
									id="transactionValue"
									label={t(
										'Transaction/estimated value (excl.transaction cost)'
									)}
									display={configuration.displayTransactionValue}
									value={transactionValue}
									unit="EUR"
									valueChanged={transactionValueChanged}
									errors={errors.transactionValue}
								/>
                                <LabeledDropdownComponent
                                    id="transactionType"
                                    label={t('Transaction type')}
                                    display={configuration.displayTransactionType}
                                    options={transactionTypeOptions}
                                    value={transactionType}
                                    valueChanged={transactionTypeChanged}
                                    errors={errors.transactionType}
                                />
								<LabeledNumberInputComponent
									id="transactionYear"
									label={t('Year transaction/estimated value')}
									display={configuration.displayTransactionYear}
									value={transactionYear}
									maxLength={4}
									valueChanged={transactionYearChanged}
									errors={errors.transactionYear}
									placeholder={`eg. ${new Date().getFullYear().toString()}`}
								/>
								<LabeledNumberInputComponent
									id="renovationBudget"
									label={t('Total budget renovation works')}
									display={configuration.displayRenovationBudget}
									value={renovationBudget}
									unit="EUR"
									valueChanged={renovationBudgetChanged}
									errors={errors.renovationBudget}
								/>
								<LabeledDropdownComponent
									id="valuationNeeded"
									label={t('Valuation needed')}
									display={configuration.displayValuationNeeded}
									options={valuationNeededOptions}
									value={valuationNeeded}
									valueChanged={valuationNeededChanged}
									errors={errors.valuationNeeded}
								/>
								{realEstateType === RealEstateType.RRE && valuationNeeded === 'yes' && (
									<LabeledDropdownComponent
										id="paymentStrategy"
										label={t('Payment Strategy')}
										display={configuration.displayPaymentStrategy}
										options={paymentStrategyOptions}
										value={paymentStrategy}
										valueChanged={paymentStrategyChanged}
										errors={errors.paymentStrategy}
									/>
								)}
								<LabeledTextAreaComponent display={VariableDisplay.Optional} id='extraInformation' label={t('Extra Information')} value={extraInfo} valueChanged={setExtraInfo} />
							</div>
						</div>
						{(errors.firstName ||
							errors.lastName ||
							errors.email ||
							errors.secondaryEmail ||
							errors.language ||
							errors.fullVisit ||
							errors.loanPurpose ||
							errors.transactionYear ||
							errors.transactionType ||
							errors.transactionValue ||
							errors.renovationBudget ||
							errors.paymentStrategy ||
							errors.valuationNeeded ||
							errors.nsxId) && (
							<div className={styles.errorsPanel}>
								{t('Please fill in all required fields')}
							</div>
						)}

						<div className={styles.formField}>
							<div className={`${styles.buttons} ${styles.alignLeft}`}>
								<button
									type="button"
									className={`${styles.button} ${styles.negative}`}
									onClick={() => navigateTo(RouteUrl.Invitations)}
								>
									{t('Back')}
								</button>
								<button
									type="submit"
									className={styles.button}
									onClick={e => handleFormSubmit(e)}
								>
									{inProgress ? (
										<div className={styles.loadingIcon}>
											<LoaderComponent />
										</div>
									) : (
											configuration.displayEmail !== VariableDisplay.Required
												? t('Create')
												: t('Create & Send')
									)}
								</button>
								{configuration.displayEmail !== VariableDisplay.Required &&
									webformUrl && (
										<div>
											<button
												type="submit"
												className={styles.button}
												onClick={e => goToWebform(e)}
											>
												{t('To webform')}
											</button>
										</div>
									)}
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	) : (
		<></>
	);
};

export default CreateInvitationPage;

export type InvitationErrors = {
	firstName?: string;
	lastName?: string;
	email?: string;
	secondaryEmail?: string;
	language?: string;
	fullVisit?: string;
	loanPurpose?: string;
	transactionValue?: string;
    transactionType?: string;
	transactionYear?: string;
	renovationBudget?: string;
	paymentStrategy?: string;
	fullPropertyRights?: string;
	valuationNeeded?: string;
	nsxId?: string;
	reference?: string;
	cluster?: string;
	address?: string
};

export const setFirstNameError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		firstName: errorMessage,
	};
};

export const setLastNameError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		lastName: errorMessage,
	};
};

export const setEmailError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		email: errorMessage,
	};
};

export const setSecondaryEmailError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		secondaryEmail: errorMessage,
	};
};

export const setLanguageError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		language: errorMessage,
	};
};

export const setFullVisitError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		fullVisit: errorMessage,
	};
};

export const setLoanPurposeError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		loanPurpose: errorMessage,
	};
};

export const setTransactionValueError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		transactionValue: errorMessage,
	};
};

export const setTransactionTypeError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		transactionType: errorMessage,
	};
};

export const setTransactionYearError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		transactionYear: errorMessage,
	};
};

export const setRenovationBudgetError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		renovationBudget: errorMessage,
	};
};

export const setValuationNeededError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		valuationNeeded: errorMessage,
	};
};

export const setPaymentStrategyError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		paymentStrategy: errorMessage,
	};
};

export const setFullPropertyRightsError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		fullPropertyRights: errorMessage,
	};
};

export const setNsxIdError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		nsxId: errorMessage,
	};
};

export const setReferenceError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		reference: errorMessage,
	};
};

export const setAddressError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		address: errorMessage,
	};
};

export const setClusterError = (
	errorMessage: string | undefined,
	invitationErrors: InvitationErrors
): InvitationErrors => {
	return {
		...invitationErrors,
		cluster: errorMessage,
	};
};
