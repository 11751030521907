import {Order, SortingOptions, SortType} from '../types/request/users-request';
import {UserSortOrder} from "../types/user-sort-order";

export class UserSortOrderMapper {
    public static mapToSortingOptions(type: UserSortOrder): SortingOptions {
        switch (type) {
            case UserSortOrder.EmailAscending:
                return {
                    sortType: SortType.Email,
                    orderBy: Order.Ascending,
                };
            case UserSortOrder.EmailDescending:
                return {
                    sortType: SortType.Email,
                    orderBy: Order.Descending,
                };
            case UserSortOrder.RoleAscending:
                return {
                    sortType: SortType.Role,
                    orderBy: Order.Ascending,
                };
            case UserSortOrder.RoleDescending:
                return {
                    sortType: SortType.Role,
                    orderBy: Order.Descending,
                };
            case UserSortOrder.CreatedOnAscending:
                return {
                    sortType: SortType.CreatedOn,
                    orderBy: Order.Ascending,
                };
            case UserSortOrder.CreatedOnDescending:
                return {
                    sortType: SortType.CreatedOn,
                    orderBy: Order.Descending,
                };
            default:
                return {
                    sortType: SortType.Email,
                    orderBy: Order.Descending,
                };
        }
    }
}
