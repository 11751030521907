import React, {FormEvent, FunctionComponent, useContext, useEffect, useState} from 'react';
import BankApi from '../../api/bank-api';
import styles from './bulk-upload.module.scss';
import {useTranslation} from 'react-i18next';
import {AppContext} from '../../store/app-context';
import {InvitationSendError, BulkInvitationSendResponse} from '../../types/response/bulk-invitation-send-response';
import {LayoutActions} from '../../store/layout/types';
import {ToastMessage, ToastMessageType} from '../../types/toast';
import LoaderComponent from '../../components/loader/loader';
import {useHistory, useParams} from 'react-router-dom';
import {AdminClientParams} from '../../types/admin-client-params';
import {RouteUrl} from '../../routes';
import {saveAs} from 'file-saver';
import TitleWithBack from '../../components/title-with-back/title-with-back.component';
import {InvitationConfiguration} from '../../types/invitation-configuration';
import {VariableDisplay} from '../../types/variable-display';
import {Bank} from '../../types/bank';


const BulkUploadPage: FunctionComponent = () => {
    const [file, setFile] = useState<File>();
    const [errors, setErrors] = useState<InvitationSendError[]>([]);
    const [message, setMessage] = useState<string>('');
    const [inProgress, setInProgress] = useState<boolean>(false);
    const history = useHistory();
    const {bankId} = useParams<AdminClientParams>();
    const {dispatch, state} = useContext(AppContext);
    const [bank] = useState<Bank | undefined>(state.bankState.bank);
    const [configuration, setConfiguration] = useState<InvitationConfiguration>();
    const {t} = useTranslation();

    useEffect(() => {
        if (!bankId || !bank) {
            history.push(RouteUrl.Root);
        }
    }, [bankId]);

    useEffect(() => {
        if (state.bankState.invitationConfiguration) {
            setConfiguration(state.bankState.invitationConfiguration);
        }
    }, [state.bankState.invitationConfiguration]);

    const handleFormSubmit = (e: FormEvent) => {
        e.preventDefault();
        if (file && bankId && !inProgress) {
            submitBulkUpload(bankId);
        }
    }

    const downloadTemplate = () => {
        if (configuration) {
            let file = `${InvitationCsvFields.FirstName};${InvitationCsvFields.LastName};${InvitationCsvFields.Email}`
            if (configuration.displaySecondaryEmail !== VariableDisplay.Hidden) {
                file += `;${InvitationCsvFields.SecondaryEmail}`;
            }
            file += `;${InvitationCsvFields.Address};${InvitationCsvFields.ExternalReference};${InvitationCsvFields.Language}`;
            if (configuration.displayRealEstateType !== VariableDisplay.Hidden) {
                file += `;${InvitationCsvFields.RealEstateType}`;
            }
            if (configuration.displayFullVisitValuation !== VariableDisplay.Hidden) {
                file += `;${InvitationCsvFields.FullVisitValuation}`;
            }
            if (configuration.displayFullPropertyRights !== VariableDisplay.Hidden) {
                file += `;${InvitationCsvFields.FullPropertyRights}`;
            }
            if (configuration.displayLoanPurpose !== VariableDisplay.Hidden) {
                file += `;${InvitationCsvFields.LoanPurpose}`;
            }
            if (configuration.displayTransactionValue !== VariableDisplay.Hidden) {
                file += `;${InvitationCsvFields.TransactionValue}`;
            }
            if (configuration.displayTransactionType !== VariableDisplay.Hidden) {
                file += `;${InvitationCsvFields.TransactionType}`;
            }
            if (configuration.displayTransactionYear !== VariableDisplay.Hidden) {
                file += `;${InvitationCsvFields.TransactionYear}`;
            }
            if (configuration.displayRenovationBudget !== VariableDisplay.Hidden) {
                file += `;${InvitationCsvFields.RenovationBudget}`;
            }
            if (configuration.displayNsxId !== VariableDisplay.Hidden) {
                file += `;${InvitationCsvFields.NsxId}`;
            }
            file += `;${InvitationCsvFields.CreatedBy};${InvitationCsvFields.ValuationNeeded};${InvitationCsvFields.PaymentStrategy}\n`;
            const blob = new Blob([file], {
                type: 'text/plain'
            });
            saveAs(blob, `bulk-invite-template.csv`);
        }
    }

    const submitBulkUpload = (bankId: string) => {
        if (!bankId || bankId === 'undefined') {
            dispatch({
                type: LayoutActions.AddToast,
                toast: new ToastMessage(ToastMessageType.Error, t('Could not find the correct bank. Please refresh the page and try again.')),
            });
        } else if (file) {
            setInProgress(true);
            BankApi.addBulkUpload(bankId, file)
                .then((response: BulkInvitationSendResponse) => {
                    setInProgress(false);
                    if (!response.errors) {
                        dispatch({
                            type: LayoutActions.AddToast,
                            toast: new ToastMessage(ToastMessageType.Success, t('Invitations successfully send'))
                        });
                    } else {
                        if (response.message) {
                            setMessage(response.message);
                        }
                        setErrors(response.errors);
                        dispatch({
                            type: LayoutActions.AddToast,
                            toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.'))
                        });
                    }
                })
                .catch((err) => {
                    dispatch({
                        type: LayoutActions.AddToast,
                        toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.'))
                    });
                })
                .finally(() => {
                    setFile(undefined)
                });
        }
    }

    const handleFile = (fileList: FileList | null) => {
        if (fileList && fileList.length === 1) {
            setFile(fileList[0]);
            setErrors([]);
            setMessage('');
        }
    }

    const navigateTo = (route: RouteUrl) => {
        history.push(route.replace(':bankId', bankId));
    }

    return configuration ? (
        <div className={styles.page}>
            <div className={styles.content}>
                <div className={styles.panel}>
                    <TitleWithBack title={t('Bulk Invite')} onBackClick={() => {navigateTo(RouteUrl.Invitations)}}/>
                    <div className={styles.formInfo}>
                        <div>{t('Currently only accepts .csv files, with semicolon as delimiter.')}</div>
                        <div>{t('Following fields are configured for this account:')}</div>
                        <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.FirstName}:</div>
                            <div className={styles.type}>string</div>
                        </div>
                        <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.LastName}:</div>
                            <div className={styles.type}>string</div>
                        </div>
                        <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.Email}:</div>
                            <div className={styles.type}>string</div>
                        </div>
                        {configuration.displaySecondaryEmail !== VariableDisplay.Hidden && <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.SecondaryEmail}:</div>
                            <div className={styles.type}>string {configuration.displaySecondaryEmail === VariableDisplay.Optional && '(optional)'}</div>
                        </div>}
                        <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.Address}:</div>
                            <div className={styles.type}>string (optional)</div>
                        </div>
                        <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.ExternalReference}:</div>
                            <div className={styles.type}>string (optional)</div>
                        </div>
                        <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.Language}:</div>
                            <div className={styles.type}>en | nl | fr</div>
                        </div>
                        {configuration.displayRealEstateType !== VariableDisplay.Hidden && <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.RealEstateType}:</div>
                            <div className={styles.type}>RRE | CRE {configuration.displayRealEstateType === VariableDisplay.Optional && '(optional)'}</div>
                        </div>}
                        {configuration.displayFullVisitValuation !== VariableDisplay.Hidden && <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.FullVisitValuation}:</div>
                            <div className={styles.type}>yes | no {configuration.displayFullVisitValuation === VariableDisplay.Optional && '(optional)'}</div>
                        </div>}
                        {configuration.displayFullPropertyRights !== VariableDisplay.Hidden && <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.FullPropertyRights}:</div>
                            <div className={styles.type}>yes | no {configuration.displayFullPropertyRights === VariableDisplay.Optional && '(optional)'}</div>
                        </div>}
                        {configuration.displayLoanPurpose !== VariableDisplay.Hidden && <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.LoanPurpose}:</div>
                            <div className={styles.type}>
                                Purchase | NewBuilding | TotalRenovation | PartialRenovation | PurchaseAndTotalRenovation | PurchaseAndPartialRenovation
                                {configuration.displayLoanPurpose === VariableDisplay.Optional && '(optional)'}
                            </div>
                        </div>}
                        {configuration.displayTransactionValue !== VariableDisplay.Hidden && <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.TransactionValue}:</div>
                            <div className={styles.type}>number {configuration.displayTransactionValue === VariableDisplay.Optional && '(optional)'}</div>
                        </div>}
                        {configuration.displayTransactionType !== VariableDisplay.Hidden && <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.TransactionType}:</div>
                            <div className={styles.type}>Compromise | AskingPrice | OwnValuation {configuration.displayTransactionValue === VariableDisplay.Optional && '(optional)'}</div>
                        </div>}
                        {configuration.displayTransactionYear !== VariableDisplay.Hidden && <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.TransactionYear}:</div>
                            <div className={styles.type}>number {configuration.displayTransactionYear === VariableDisplay.Optional && '(optional)'}</div>
                        </div>}
                        {configuration.displayRenovationBudget !== VariableDisplay.Hidden && <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.RenovationBudget}:</div>
                            <div className={styles.type}>number {configuration.displayRenovationBudget === VariableDisplay.Optional && '(optional)'}</div>
                        </div>}
                        {configuration.displayNsxId !== VariableDisplay.Hidden && <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.NsxId}:</div>
                            <div className={styles.type}>string {configuration.displayNsxId === VariableDisplay.Optional && '(optional)'}</div>
                        </div>}
                        <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.CreatedBy}:</div>
                            <div className={styles.type}>string (email)</div>
                        </div>
                        <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.ValuationNeeded}:</div>
                            <div className={styles.type}>yes | no</div>
                        </div>
                        <div className={styles.csvField}>
                            <div className={styles.key}>{InvitationCsvFields.PaymentStrategy}:</div>
                            <div className={styles.type}>{bank?.paymentStrategies.join(' | ')}</div>
                        </div>
                        <div className={styles.download} onClick={() => downloadTemplate()}>{t('Download template file')}</div>
                    </div>
                    <form>
                        <div className={styles.formField}>
                            <div className={styles.labelContainer}>
                                <label className={styles.label} htmlFor="file">
                                    {t('Select your csv file')}
                                </label>
                            </div>
                            <div>
                                <input
                                    className={styles.input}
                                    id="file"
                                    type="file"
                                    max={1}
                                    onChange={(e) => handleFile(e.target.files)}
                                    accept=".csv"
                                />
                            </div>
                        </div>
                        {(errors && errors.length > 0) || message ? <div className={styles.errorField}>
                            {message ? <div>{message}</div> : null}
                            {errors.map((error, index) => {
                                return <div key={`key-${index}`}>Line {error.lineNumber}: {error.error}</div>
                            })}
                        </div> : null}
                        <div className={styles.formField}>
                            <div className={`${styles.buttons} ${styles.alignLeft}`}>
                                <button className={`${styles.button} ${styles.negative}`}
                                        onClick={() => navigateTo(RouteUrl.Invitations)}>{t('Back')}</button>
                                <button className={styles.button} onClick={(e) => handleFormSubmit(e)}>{inProgress ?
                                    <div className={styles.loadingIcon}>
                                        <LoaderComponent/>
                                    </div> : t("Upload")}</button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    ) : <></>
}

export default BulkUploadPage;

export enum InvitationCsvFields {
    FirstName = 'first_name',
    LastName = 'last_name',
    Email = 'email',
    SecondaryEmail = 'secondary_email',
    Address = 'address',
    ExternalReference = 'external_reference',
    Language = 'language',
    FullVisitValuation = 'full_visit_valuation',
    FullPropertyRights = 'full_property_rights',
    LoanPurpose = 'loan_purpose',
    TransactionValue = 'transaction_value',
    TransactionType = 'transaction_type',
    TransactionYear = 'transaction_year',
    RenovationBudget = 'renovation_budget',
    NsxId = 'nsx_id',
    CreatedBy = 'created_by',
    ValuationNeeded = 'valuation_needed',
    PaymentStrategy = 'payment_strategy',
    RealEstateType = 'real_estate_type',
}
