import {BankActionTypes} from './types';
import {AppAction} from '../types';
import {Bank} from '../../types/bank';
import {InvitationConfiguration} from '../../types/invitation-configuration';
import {WebformConfiguration} from '../../types/webform-configuration';
import {AccountCaseIdentifier} from "../../types/account-case-identifier";

export type BankState = {
    bank?: Bank;
    invitationConfiguration?: InvitationConfiguration;
    webformConfiguration?: WebformConfiguration;
    accountCaseIdentifiers: AccountCaseIdentifier[];
    currentAccountCaseIdentifier?: AccountCaseIdentifier;
};

export const initialBankState: BankState = {
    bank: undefined,
    invitationConfiguration: undefined,
    webformConfiguration: undefined,
    accountCaseIdentifiers: [],
    currentAccountCaseIdentifier: undefined,
};

export const bankReducer = (state: BankState, action: AppAction): BankState => {
    switch (action.type) {
        case BankActionTypes.SetBank:
            return {
                bank: action.bank,
                invitationConfiguration: action.bank ? action.bank.invitationConfiguration : undefined,
                webformConfiguration: action.bank ? action.bank.webformConfiguration : undefined,
                accountCaseIdentifiers: action.bank ? state.accountCaseIdentifiers : [],
                currentAccountCaseIdentifier: action.bank ? state.currentAccountCaseIdentifier : undefined,
            };
        case BankActionTypes.SetInvitationConfiguration:
            return {...state, invitationConfiguration: action.configuration};
        case BankActionTypes.SetWebformConfiguration:
            return {...state, webformConfiguration: action.configuration};
        case BankActionTypes.SetAccountCaseIdentifiers:
            return {...state, accountCaseIdentifiers: action.accountCaseIdentifiers};
        case BankActionTypes.SetCurrentAccountCaseIdentifier:
            return {...state, currentAccountCaseIdentifier: action.accountCaseIdentifier};
        default:
            return state;
    }
};
