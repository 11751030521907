import React, { ChangeEvent } from 'react';
import styles from './toggle-switch.module.scss';

const ToggleSwitchComponent: React.FC<OwnProps> = ({
	isChecked,
	onChange,
	id,
	name,
	disabled = false,
}) => {
	return (
		<div className={styles.toggleSwitch}>
			<input
				type="checkbox"
				className={styles.toggleSwitchCheckbox}
				name={name}
				id={id}
				checked={isChecked}
				onChange={onChange}
				disabled={disabled}
			/>
			<label className={styles.toggleSwitchLabel} htmlFor={name}>
				<span
					className={`${
						disabled
							? `${styles.toggleSwitchInner} ${styles.toggleSwitchDisabled}`
							: `${styles.toggleSwitchInner}`
					}`}
				/>
				<span
					className={`${
						disabled
							? `${styles.toggleSwitchSwitch} ${styles.toggleSwitchDisabled}`
							: styles.toggleSwitchSwitch
					}`}
				/>
			</label>
		</div>
	);
};

export default ToggleSwitchComponent;

interface OwnProps {
	isChecked: boolean;
	name: string;
	id: string;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
}
