
export interface InvitationsRequest {
    sorting?: SortingOptions;
    filter?: string;
    showPendingOnly: boolean;
    page: number;
    itemsPerPage: number;
}

export interface SortingOptions {
    sortType: SortType;
    orderBy: Order;
}

export enum Order {
    Ascending = 'ASC',
    Descending = 'DESC'
}

export enum SortType {
    UpdatedOn = 'updatedOn',
    Email = 'email',
    Status = 'status',
    Address = 'address',
}
