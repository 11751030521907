import React, {
	FormEvent,
	FunctionComponent,
	useContext,
	useEffect,
	useState,
} from 'react';
import styles from './landing.page.module.scss';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../store/app-context';
import DropdownComponent, {
	SelectionOption,
} from '../../components/dropdown/dropdown.component';
import { Bank } from '../../types/bank';
import { BankActionTypes } from '../../store/bank/types';
import { useHistory } from 'react-router-dom';
import { RouteUrl } from '../../routes';
import { LayoutActions } from '../../store/layout/types';
import BankApi from '../../api/bank-api';

const LandingPage: FunctionComponent = () => {
	const [bankOptions, setBankOptions] = useState<SelectionOption[]>([]);
	const [selectedBank, setSelectedBank] = useState<Bank>();
	const [banks, setBanks] = useState<Bank[]>([]);
	const { state, dispatch } = useContext(AppContext);
	const { t } = useTranslation();
	const history = useHistory();

	useEffect(() => {
		dispatch({ type: LayoutActions.ShowHeader });
		if (state.userState.user && state.userState.user.banks) {
			if (state.userState.user.banks.length === 1) {
				getBank(state.userState.user.banks[0].id);
			} else {
				const userBanks: SelectionOption[] = state.userState.user.banks.map(
					bank => {
						return { key: bank.id, value: bank.id, label: bank.name };
					}
				);
				setBanks(state.userState.user.banks);
				setBankOptions(userBanks);
				setSelectedBank(state.userState.user.banks[0]);
			}
		}
	}, [state.userState.user]);

	const handleFormSubmit = (e: FormEvent) => {
		e.preventDefault();
		if (selectedBank) {
			getBank(selectedBank.id);
		}
	};

	const getBank = async (bankId: string) => {
		const bank = await BankApi.getBankWithConfiguration(bankId);
		if (bank) {
			dispatch({ type: BankActionTypes.SetBank, bank });
			history.push(RouteUrl.Invitations.replace(':bankId', bankId));
		}
	};

	return (
		<div className={styles.page}>
			{bankOptions.length > 0 && banks.length > 0 ? (
				<div className={styles.content}>
					<div className={styles.panel}>
						<form onSubmit={e => handleFormSubmit(e)}>
							<div className={styles.formField}>
								<div className={styles.labelContainer}>
									<label className={styles.label}>
										{t('Select your account')}
									</label>
								</div>
								<div className={styles.dropdownInput}>
									<DropdownComponent
										options={bankOptions}
										onSelectionChanged={bankId =>
											setSelectedBank(banks.find(bank => bankId === bank.id))
										}
										selectedOptionValue={
											bankOptions.find(
												option => option.key === selectedBank?.id
											)!.key
										}
									/>
								</div>
							</div>
							<div className={styles.formField}>
								<div className={`${styles.buttons} ${styles.alignLeft}`}>
									<button className={styles.button}>{t('Confirm')}</button>
								</div>
							</div>
						</form>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default LandingPage;
