import React, {FunctionComponent, useEffect, useState} from 'react';
import styles from './configuration.module.scss';
import {useTranslation} from 'react-i18next';
import {AccountCaseIdentifier} from '../../types/account-case-identifier';
import UnlabeledInputComponent from '../../components/input/unlabeled-input.component';
import {Bank, PaymentStrategy} from '../../types/bank';
import MultiSelectComponent from '../../components/multi-select/multi-select.component';
import {enumToSelectionOptions} from '../../components/dropdown/selection-option.util';
import {SelectionOption} from '../../components/dropdown/dropdown.component';
import ReactTooltip from 'react-tooltip';
import exclamationMarkIcon from '../../../assets/images/icon_Exclamation Mark.svg';
import {ReactComponent as Info} from '../../../assets/images/info.svg';

const AccountConfigurationComponent: FunctionComponent<OwnProps> = ({isNew = false, initialBank, initialAccountCaseIdentifier, onChanged, isAdmin = false}: OwnProps) => {
    const {t} = useTranslation();
    const [editMode, setEditMode] = useState<boolean>(false);
    const [identifier, setIdentifier] = useState<AccountCaseIdentifier>();
    const [bank, setBank] = useState<Bank>(initialBank);
    const [selectedPaymentStrategyOptions, setSelectedPaymentStrategyOptions] = useState<SelectionOption[]>([]);
    const [error, setError] = useState<string>();

    const paymentStrategyOptions = enumToSelectionOptions(PaymentStrategy, t);

    useEffect(() => {
        if (isNew) {
            setEditMode(true);
        }
    }, [isNew]);

    useEffect(() => {
        if (initialAccountCaseIdentifier) {
            setIdentifier(initialAccountCaseIdentifier);
        }
    }, [initialAccountCaseIdentifier]);

    useEffect(() => {
        if (isNew) {
            if (!bank.name || bank.paymentStrategies.length === 0) {
                setError(t('Name and at least 1 payment strategy are required'));
            } else {
                onChanged(bank, identifier);
                setError('');
            }

        }
    }, [bank, identifier]);

    useEffect(() => {
        if (initialBank) {
            setBank(initialBank);
            const initialPaymentStrategyOptions: SelectionOption[] = [];
            bank.paymentStrategies.forEach(ps => {
                const option = paymentStrategyOptions.find(o => o.value === ps);
                if (option) {
                    initialPaymentStrategyOptions.push(option);
                }
            });
            setSelectedPaymentStrategyOptions(initialPaymentStrategyOptions);
        }
    }, [initialBank]);

    const onSave = () => {
        if (bank) {
            if (!bank.name || bank.paymentStrategies.length === 0) {
                setError(t('Name and at least 1 payment strategy are required'));
            } else {
                onChanged(bank, identifier);
                setEditMode(false);
                setError('');
            }

        }
    }

    const onCancel = () => {
        setIdentifier(initialAccountCaseIdentifier);
        setBank(initialBank);
        setEditMode(false);
    }

    const onEmailTemplateChanged = (value: string) => {
        const bankCopy = Object.assign({}, bank);
        bankCopy.emailTemplateKey = value;
        setBank(bankCopy);
    }

    const onAccountNameChanged = (value: string) => {
        const bankCopy = Object.assign({}, bank);
        bankCopy.name = value;
        setBank(bankCopy);
    }

    const onAccountCaseIdentifierChanged = (value: string) => {
        if (identifier) {
            const identifierCopy = Object.assign({}, identifier);
            identifierCopy.accountCaseId = value;
            setIdentifier(identifierCopy);
        } else {
            const today = new Date();
            const newIdentifier: AccountCaseIdentifier = {
                id: 'new',
                accountCaseId: value,
                name: `${bank.name} ${today.getFullYear()}`,
                activeFrom: today,
                activeUntil: today,
            };
            setIdentifier(newIdentifier);
        }
    }

    const onPaymentStrategiesChanged = (options: SelectionOption[]) => {
        const bankCopy = Object.assign({}, bank);
        bankCopy.paymentStrategies = options.map(option => PaymentStrategy[option.value! as keyof typeof PaymentStrategy]);
        setBank(bankCopy);
        setSelectedPaymentStrategyOptions(options);
    }

    return bank ? (
        <div className={styles.configurationPanel}>
            <div className={styles.panelTitle}>
                <div>
                    {t('Account configuration')}
                    {error &&
                    <div className={styles.invalid}>
                        <ReactTooltip id="error-tooltip" effect="solid"/>
                        <img src={exclamationMarkIcon} data-tip={error} data-for="error-tooltip"/>
                    </div>}
                </div>
                {!isNew && isAdmin && !editMode && <div className={styles.buttons}>
                    <button className={styles.button} onClick={() => setEditMode(true)}>{t('Edit')}</button>
                </div>}
                {!isNew && isAdmin && editMode && <div className={styles.buttons}>
                    <button className={`${styles.button} ${styles.negative}`} onClick={() => onCancel()}>{t('Cancel')}</button>
                    <button className={styles.button} onClick={() => onSave()}>{t('Save')}</button>
                </div>}
            </div>
            <div className={styles.field}>
                <div className={styles.label}>{t('Account name')}:</div>
                {!editMode && <div className={styles.value}>{bank.name}</div>}
                {editMode && <UnlabeledInputComponent value={bank.name} valueChanged={(value) => onAccountNameChanged(value)}/>}
            </div>
            <div className={styles.field}>
                <div className={styles.label}>{t('Invitation email')}:</div>
                <div className={styles.value}>{bank.inviteEmail}</div>
            </div>
            <div className={styles.field}>
                <div className={styles.label}>{t('Support email')}:</div>
                <div className={styles.value}>{bank.supportEmail}</div>
            </div>
            <div className={styles.field}>
                <div className={styles.label}>{t('Created On')}:</div>
                <div className={styles.value}>{new Date(bank.createdOn).toLocaleDateString()}</div>
            </div>
            {isAdmin && <div className={styles.field}>
                <div className={styles.label}>
                    {t('Email template key')}:
                    {editMode && <div className={styles.infoIconBox}>
                        <ReactTooltip id="email-template-key-tooltip" effect="solid" place="right"/>
                        <Info className={styles.infoIcon}
                                       data-tip={t('If no email template key is provided, no mails will be sent')}
                                       data-for="email-template-key-tooltip" />
                        </div>}
                    </div>
                {!editMode && <div className={styles.value}>{bank.emailTemplateKey}</div>}
                {editMode && <UnlabeledInputComponent value={bank.emailTemplateKey} valueChanged={(value) => onEmailTemplateChanged(value)}/>}
            </div>}
            <div className={styles.field}>
                <div className={styles.label}>{t('Payment strategies')}:</div>
                {!editMode && <div className={styles.value}>{bank.paymentStrategies.join(', ')}</div>}
                {editMode && <MultiSelectComponent options={paymentStrategyOptions}
                                                   onSelectionsChanged={onPaymentStrategiesChanged}
                                                   selection={selectedPaymentStrategyOptions}
                                                   allowMultiSelect={true} />}
            </div>
            {isAdmin && <div className={styles.field}>
                <div className={styles.label}>
                    {t('Nsx dossier id')}:
                    {editMode && <div className={styles.infoIconBox}>
                        <ReactTooltip id="nxs-id-tooltip" effect="solid" place="right" />
                        <Info className={styles.infoIcon} data-tip={t('If no nsx dossier id is provided, no data will be sent to nsx')} data-for="nxs-id-tooltip" />
                    </div>}
                </div>
                {!editMode && identifier && <div className={styles.value}>{identifier.accountCaseId}</div>}
                {editMode && <UnlabeledInputComponent value={identifier ? identifier.accountCaseId : ''} valueChanged={(value) => onAccountCaseIdentifierChanged(value)}/>}
            </div>}
        </div>
    ): <></>
}
export default AccountConfigurationComponent;

type OwnProps = {
    isNew?: boolean;
    isAdmin: boolean;
    initialBank: Bank;
    initialAccountCaseIdentifier?: AccountCaseIdentifier;
    onChanged: (bank: Bank, accountCaseIdentifier?: AccountCaseIdentifier) => void;
}
