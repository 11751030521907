export class RuntimeConfig {
    public static getApiURL(): string {
        return this.getRuntimeConfig('apiUrl');
    }

    public static getClientId(): string {
        return this.getRuntimeConfig('clientId');
    }

    public static getAuthority(): string {
        return this.getRuntimeConfig('authority');
    }

    public static getRedirectUri(): string {
        return this.getRuntimeConfig('redirectUri');
    }

    public static getApiScope(): string {
        return this.getRuntimeConfig('apiScope');
    }

    public static getWebformUrl(): string {
        return this.getRuntimeConfig('webformUrl');
    }

    private static getRuntimeConfig(configProp: string): string {
        // @ts-ignore
        return window.runConfig[configProp];
    }
}
