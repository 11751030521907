export interface Bank {
    id: string;
    name: string;
    inviteEmail: string;
    supportEmail: string;
    paymentStrategies: PaymentStrategy[];
    createdOn: Date;
    updatedOn: Date;
    emailTemplateKey: string;
    hasGuidelines?: boolean;
}

// t('ClientPayment')
// t('BankPayment')
// t('NoPayment')
export enum PaymentStrategy {
    ClientPayment = 'ClientPayment',
    BankPayment = 'BankPayment',
    // NoPayment = 'NoPayment',
}


