import React, {FunctionComponent, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import DropdownComponent, {SelectionOption} from "../dropdown/dropdown.component";
import styles from './paging.module.scss';
import {conditionalClassLister} from "../../utils/class-helper";

const PagingComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {currentPage, initialItemsPerPage, total, onPreviousPage, onNextPage, onJumpToPage, onItemsPerPageChanged} = props;
    const [itemsPerPage, setItemsPerPage] = useState<number>(initialItemsPerPage);
    const [info, setInfo] = useState<PageInfo>();
    const {t} = useTranslation();
    const placeHolderText = '...';

    useEffect(() => {
        onItemsPerPageChanged(itemsPerPage);
    }, [itemsPerPage]);

    useEffect(() => {
        setInfo(buildInfo);
    }, [currentPage, itemsPerPage, total]);

    const pagingOptions: SelectionOption[] = [
        {key: ItemsPerPage.Ten, label: ItemsPerPage.Ten},
        {key: ItemsPerPage.TwentyFive, label: ItemsPerPage.TwentyFive},
        {key: ItemsPerPage.Fifty, label: ItemsPerPage.Fifty},
        {key: ItemsPerPage.Hundred, label: ItemsPerPage.Hundred},
    ];

    const itemsPerPageChanged = (option: string) => {
        const items = parseInt(option, 10);
        if (!isNaN(items)) {
            setItemsPerPage(items);
        }
    }

    const jumpToPage = (index: string) => {
        onJumpToPage(parseInt(index, 10));
    }

    const previousPage = () => {
        if (currentPage > 1) {
            onPreviousPage();
        }
    }

    const nextPage = (maxPages: number) => {
        if (currentPage < maxPages) {
            onNextPage();
        }
    }

    const buildInfo = (): PageInfo => {
        const from = ((currentPage - 1) * itemsPerPage) + 1;
        const to = (currentPage * itemsPerPage);
        const totalPages = Math.ceil(total / itemsPerPage);
        const allPages: string[] = [];

        if (totalPages <= 6) {
            let pageCount = 1;
            while (pageCount <= totalPages) {
                allPages.push(pageCount.toString());
                pageCount += 1;
            }
        } else if (currentPage < 4) {
            allPages.push('1');
            allPages.push('2');
            allPages.push('3');
            allPages.push('4');
            allPages.push(placeHolderText);
            allPages.push(totalPages.toString());
        } else if (currentPage + 2 >= totalPages) {
            allPages.push('1');
            allPages.push(placeHolderText);
            allPages.push((totalPages - 3).toString());
            allPages.push((totalPages - 2).toString());
            allPages.push((totalPages - 1).toString());
            allPages.push(totalPages.toString());
        } else {
            allPages.push('1');
            allPages.push(placeHolderText);
            allPages.push((currentPage - 1).toString());
            allPages.push((currentPage).toString());
            allPages.push((currentPage + 1).toString());
            allPages.push(placeHolderText);
            allPages.push(totalPages.toString());
        }

        return {from, to, totalPages, shortCuts: allPages};
    }

    return info ? (
        <div className={styles.pagingContainer}>
            <div>{t('Showing {{from}} to {{to}} of {{total}} results', {
                total,
                from: info.from,
                to: total > info.to ? info.to : total,
            })}</div>
            <div className={styles.rightPanel}>
                <div className={styles.changePage} onClick={() => previousPage()}>{t('Previous')}</div>
                <div className={styles.pageNumberContainer}>
                    {info.shortCuts.map((pageIndex, i) => {
                        const pageClasses = conditionalClassLister(styles)({
                            placeholderNumber: pageIndex === placeHolderText,
                            pageNumber: pageIndex !== placeHolderText,
                            pageNumberActive: pageIndex === currentPage.toString(),
                        });
                        return <div key={i} className={pageClasses}
                                    onClick={() => jumpToPage(pageIndex)}>{pageIndex}</div>;
                    })}
                </div>
                <div className={styles.changePage} onClick={() => nextPage(info.totalPages)}>{t('Next')}</div>
            </div>
            <div>
                <span className={styles.itemsPerPage}>{t('Items per page: ')}</span>
                <DropdownComponent width={'80px'} options={pagingOptions}
                                   selectedOptionValue={pagingOptions.find(o => parseInt(o.label, 10) === itemsPerPage)?.key?.toString()}
                                   onSelectionChanged={(option) => itemsPerPageChanged(option)}
                />
            </div>
        </div>
    ) : null;
}

export default PagingComponent;

interface OwnProps {
    currentPage: number;
    initialItemsPerPage: number;
    total: number;
    onPreviousPage: () => void;
    onNextPage: () => void;
    onJumpToPage: (index: number) => void;
    onItemsPerPageChanged: (items: number) => void;
}

interface PageInfo {
    from: number;
    to: number;
    totalPages: number;
    shortCuts: string[];
}

enum ItemsPerPage {
    Ten = '10',
    TwentyFive = '25',
    Fifty = '50',
    Hundred = '100',
}
