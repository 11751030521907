import React, {FunctionComponent} from 'react';
import {VariableDisplay} from '../../types/variable-display';
import styles from './input.module.scss';
import {conditionalClassLister} from '../../utils/class-helper';
import ReactTooltip from 'react-tooltip';
import exclamationMarkIcon from '../../../assets/images/icon_Exclamation Mark.svg';
import {useTranslation} from 'react-i18next';

const UnlabeledInputComponent: FunctionComponent<OwnProps> = ({errors, value, valueChanged}: OwnProps) => {
    const {t} = useTranslation();

    return (<div className={styles.formField}>
            <div className={styles.inputContainer}>
                <input type="text"
                       className={conditionalClassLister(styles)({
                           input: true,
                           inputInvalid: errors,
                       })}
                       style={{marginTop: '0'}}
                       value={value}
                       onChange={(e) => valueChanged(e.target.value)}/>
                {errors &&
                    <div className={styles.invalid}>
                        <ReactTooltip effect="solid"/>
                        <img src={exclamationMarkIcon} data-tip={errors}/>
                    </div>}
            </div>
        </div>
    );
}
export default UnlabeledInputComponent;

type OwnProps = {
    errors?: string;
    value: string;
    valueChanged: (value: string) => void;
}
