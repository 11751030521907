import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './app/app';
import i18n from './i18n';
import {MsalProvider} from '@azure/msal-react';
import {msalInstance} from './auth-config';
import { BrowserRouter as Router } from 'react-router-dom';

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Router>
                <App i18n={i18n}/>
            </Router>
        </MsalProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
