import {Http} from '../http/http';
import {ApiUrls, UrlParamBuilder} from './api-urls';
import {BulkInvitationSendResponse} from '../types/response/bulk-invitation-send-response';
import {InvitationsRequest} from '../types/request/invitations-request';
import {UsersRequest} from '../types/request/users-request';
import {InvitationRequest} from '../types/request/invitation-request';
import {Paged} from '../types/paged';
import {Invitation} from '../types/invitation';
import {User} from '../types/user';
import {BankWithConfiguration} from '../types/bank-with-configuration';
import {InvitationConfiguration} from '../types/invitation-configuration';
import {WebformConfiguration} from '../types/webform-configuration';
import {AccountCaseIdentifier} from '../types/account-case-identifier';
import {UpdateBankRequest} from '../types/request/update-bank-request';
import {CreateBankRequest} from '../types/request/create-bank-request';
import {FileNamesPerLanguage} from "../pages/guidelines/guidelines.page";

class BankApi {

    public static async createBank(request: CreateBankRequest): Promise<BankWithConfiguration> {
        return await Http.post<CreateBankRequest, BankWithConfiguration>(
            ApiUrls.CreateBank,
            request,
        );
    }

    public static async getBankWithConfiguration(bankId: string): Promise<BankWithConfiguration> {
        return await Http.get(
            ApiUrls.Bank,
            new UrlParamBuilder().addBankId(bankId).build(),
        );
    }

    public static async updateInvitationConfiguration(bankId: string, configuration: InvitationConfiguration): Promise<InvitationConfiguration> {
        return await Http.put<InvitationConfiguration, InvitationConfiguration>(
            ApiUrls.UpdateInvitationConfiguration,
            configuration,
            new UrlParamBuilder().addBankId(bankId).build(),
        );
    }

    public static async updateWebformConfiguration(bankId: string, configuration: WebformConfiguration): Promise<WebformConfiguration> {
        return await Http.put<WebformConfiguration, WebformConfiguration>(
            ApiUrls.UpdateWebformConfiguration,
            configuration,
            new UrlParamBuilder().addBankId(bankId).build(),
        );
    }

    public static async getAccountCaseIdentifiers(bankId: string): Promise<AccountCaseIdentifier[]> {
        return await Http.get<AccountCaseIdentifier[]>(
            ApiUrls.AccountCaseIdentifiers,
            new UrlParamBuilder().addBankId(bankId).build()
        );
    }

    public static async updateAccountCaseIdentifiers(bankId: string, accountCaseIdentifiers: AccountCaseIdentifier[]): Promise<AccountCaseIdentifier[]> {
        return await Http.put<AccountCaseIdentifier[], AccountCaseIdentifier[]>(
            ApiUrls.AccountCaseIdentifiers,
            accountCaseIdentifiers,
            new UrlParamBuilder().addBankId(bankId).build(),
        );
    }

    public static async updateBankInfo(bankId: string, request: UpdateBankRequest): Promise<void> {
        return await Http.put<UpdateBankRequest, void>(
            ApiUrls.Bank,
            request,
            new UrlParamBuilder().addBankId(bankId).build(),
        );
    }

    public static async addInvitationToBank(bankId: string, invitationRequest: InvitationRequest): Promise<Invitation> {
        return await Http.post<InvitationRequest, Invitation>(
            ApiUrls.PostBankInvitation,
            invitationRequest,
            new UrlParamBuilder().addBankId(bankId).build(),
        );
    }

    public static async addBulkUpload(bankId: string, file: File): Promise<BulkInvitationSendResponse> {
        const formData = new FormData();
        formData.append('file', file);
        return await Http.post<FormData, BulkInvitationSendResponse>(
            ApiUrls.PostBankInvitationBulk,
            formData,
            new UrlParamBuilder().addBankId(bankId).build()
        );
    }

    public static async getInvitationsForBank(bankId: string, body: InvitationsRequest): Promise<Paged<Invitation>> {
        return await Http.post<InvitationsRequest, Paged<Invitation>>(
            ApiUrls.GetBankInvitations,
            body,
            new UrlParamBuilder().addBankId(bankId).build(),
        );
    }

    public static async getInvitation(bankId: string, invitationId: string): Promise<Invitation> {
        return await Http.get<Invitation>(
            ApiUrls.GetBankInvitation,
            new UrlParamBuilder().addBankId(bankId).addInvitationId(invitationId).build(),
        );
    }

    public static async resendInvitation(bankId: string, invitationId: string): Promise<Invitation> {
        return await Http.get<Invitation>(
            ApiUrls.ResendInvitation,
            new UrlParamBuilder().addBankId(bankId).addInvitationId(invitationId).build(),
        );
    }

    public static async getFiles(bankId: string, invitationToken: string): Promise<Blob> {
        return await Http.getBlob(
            ApiUrls.GetBankInvitationFiles,
            new UrlParamBuilder().addBankId(bankId).addInvitationToken(invitationToken).build(),
        );
    }

    public static async toggleProcessedStatus(bankId: string, invitationId: string): Promise<Invitation> {
        return await Http.put(
            ApiUrls.ToggleBankInvitationStatus,
            {},
            new UrlParamBuilder().addBankId(bankId).addInvitationId(invitationId).build(),
        );
    }

    public static async getUsersForBank(bankId: string, body: UsersRequest): Promise<Paged<User>> {
        return await Http.post<UsersRequest, Paged<User>>(
            ApiUrls.GetUsersForBank,
            body,
            new UrlParamBuilder().addBankId(bankId).build(),
        );
    }

    public static async deleteInvitation(bankId: string, invitationId: string): Promise<Invitation> {
        return await Http.put(
            ApiUrls.DeleteInvitationFromBank,
            {},
            new UrlParamBuilder().addBankId(bankId).addInvitationId(invitationId).build(),
        );
    }

    public static async getFileNames(bankId: string): Promise<FileNamesPerLanguage[]> {
        return await Http.get(
            ApiUrls.BankFiles,
            new UrlParamBuilder().addBankId(bankId).build(),
        )
    }

    public static async getFile(bankId: string, fileName: string, language: string): Promise<Blob> {
        return await Http.getBlobWithPost(
            ApiUrls.BankFiles,
            {fileName, language},
            new UrlParamBuilder().addBankId(bankId).build(),
        )
    }
}

export default BankApi;
