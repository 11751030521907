import {ErrorActionTypes} from './types';
import {AppAction} from '../types';
import {ErrorContract} from '../../types/error';

export type ErrorState = {
    error?: ErrorContract;
};

export const initialErrorState: ErrorState = {
    error: undefined
};

export const errorReducer = (state: ErrorState, action: AppAction): ErrorState => {
    switch (action.type) {
        case ErrorActionTypes.SetError:
            return {error: action.error};
        default:
            return state;
    }
};
