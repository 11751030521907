import React, {FunctionComponent} from 'react';
import {Route, Switch} from 'react-router-dom';
import CreateInvitationPage from './pages/create-invitation/create-invitation.page';
import BulkUploadPage from './pages/bulk-upload/bulk-upload.page';
import LandingPage from './pages/landing/landing.page';
import InvitationsPage from './pages/invitations/invitations.page';
import AccountPage from './pages/account/account.page';
import ErrorPage from './pages/error/error.page';
import UsersPage from './pages/users/users.page';
import CreateUserPage from './pages/create-user/create-user.page';
import InvitationDetailsPage from './pages/invitation-details/invitation-details.page';
import GuidelinesPage from './pages/guidelines/guidelines.page';
import CreateAccountPage from './pages/create-account/create-account.page';

export enum RouteUrl {
    Root = '/',
    Invitations = '/:bankId/invitations',
    CreateInvitation = '/:bankId/invitations/invitation',
    BulkInvitation = '/:bankId/invitations/bulk-upload',
    InvitationDetails = '/:bankId/invitations/:invitationId',
    Account = '/:bankId/account',
    CreateAccount = '/account/create',
    Error = '/error',
    Users = '/:bankId/users',
    CreateUser = '/:bankId/users/create',
    Guidelines = '/:bankId/guidelines',
}

const Routes: FunctionComponent = () => {

    return (
        <Switch>
            <Route exact path={RouteUrl.Root} component={LandingPage}/>
            <Route exact path={RouteUrl.Invitations} component={InvitationsPage}/>
            <Route exact path={RouteUrl.CreateInvitation} component={CreateInvitationPage}/>
            <Route exact path={RouteUrl.BulkInvitation} component={BulkUploadPage}/>
            <Route exact path={RouteUrl.InvitationDetails} component={InvitationDetailsPage}/>
            <Route exact path={RouteUrl.Account} component={AccountPage}/>
            <Route exact path={RouteUrl.CreateAccount} component={CreateAccountPage}/>
            <Route exact path={RouteUrl.Users} component={UsersPage}/>
            <Route exact path={RouteUrl.CreateUser} component={CreateUserPage}/>
            <Route exact path={RouteUrl.Guidelines} component={GuidelinesPage}/>
            <Route path={RouteUrl.Error} component={ErrorPage}/>
            <Route path={RouteUrl.Root} component={ErrorPage}/>
        </Switch>
    );
}

export default Routes;
