import {Invitation} from '../../types/invitation';
import {InvitationActionTypes} from './types';
import {AppAction} from '../types';

export type InvitationState = {
    invitation?: Invitation;
};

export const initialInvitationState: InvitationState = {
    invitation: undefined,
};

export const invitationReducer = (state: InvitationState, action: AppAction): InvitationState => {
    switch (action.type) {
        case InvitationActionTypes.SetInvitation:
            return {...state, invitation: action.invitation};
        default:
            return state;
    }
};
