import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import styles from './account.module.scss';
import {useTranslation} from 'react-i18next';
import {useHistory, useParams} from 'react-router-dom';
import {RouteUrl} from '../../routes';
import {AdminClientParams} from '../../types/admin-client-params';
import {AppContext} from '../../store/app-context';
import {BankActionTypes} from '../../store/bank/types';
import {UserRole} from '../../types/user';
import InvitationConfigurationComponent from './invitation-configuration.component';
import {InvitationConfiguration} from '../../types/invitation-configuration';
import {WebformConfiguration} from '../../types/webform-configuration';
import BankApi from '../../api/bank-api';
import {LayoutActions} from '../../store/layout/types';
import {ToastMessage, ToastMessageType} from '../../types/toast';
import TitleWithBack from '../../components/title-with-back/title-with-back.component';
import WebformConfigurationComponent from './webform-configuration.component';
import {Bank, PaymentStrategy} from '../../types/bank';
import {AccountCaseIdentifier} from '../../types/account-case-identifier';
import AccountConfigurationComponent from './account-configuration.component';
import {UpdateBankRequest} from '../../types/request/update-bank-request';

const AccountPage: FunctionComponent = () => {
    const {state, dispatch} = useContext(AppContext);
    const user = state.userState.user;
    const bank = state.bankState.bank;
    const [invitationConfiguration, setInvitationConfiguration] = useState<InvitationConfiguration>();
    const [webformConfiguration, setWebformConfiguration] = useState<WebformConfiguration>();
    // const [accountCaseIdentifiers, setAccountCaseIdentifiers] = useState<AccountCaseIdentifier[]>();
    const [currentAccountCaseIdentifier, setCurrentAccountCaseIdentifier] = useState<AccountCaseIdentifier>()
    const {t} = useTranslation();
    const history = useHistory();
    const {bankId} = useParams<AdminClientParams>();
    const [hasMultipleBanks, setHasMultipleBanks] = useState<boolean>(false);

    useEffect(() => {
        if (!bankId || !state.bankState.bank) {
            history.push(RouteUrl.Root);
        } else if (user?.role === UserRole.Admin) {
            getAccountCaseIdentifiers(bankId);
        }
    }, [bankId]);

    useEffect(() => {
        if (state.bankState.invitationConfiguration) {
            setInvitationConfiguration(state.bankState.invitationConfiguration);
        }
        if (state.bankState.webformConfiguration) {
            setWebformConfiguration(state.bankState.webformConfiguration);
        }
        // if (state.bankState.accountCaseIdentifiers) {
        //     setAccountCaseIdentifiers(state.bankState.accountCaseIdentifiers);
        // }
        if (state.bankState.currentAccountCaseIdentifier) {
            setCurrentAccountCaseIdentifier(state.bankState.currentAccountCaseIdentifier);
        }
    }, [state.bankState]);

    useEffect(() => {
        if (state.userState.user && state.userState.user.banks.length > 1) {
            setHasMultipleBanks(true);
        }
    }, [state.userState.user]);

    const navigateTo = (route: RouteUrl) => {
        if (route === RouteUrl.Root) {
            dispatch({type: BankActionTypes.SetBank, bank: undefined});
        }
        history.push(route.replace(':bankId', bankId));
    }

    const getBank = async (bankId: string) => {
        const bank = await BankApi.getBankWithConfiguration(bankId);
        if (bank) {
            dispatch({type: BankActionTypes.SetBank, bank});
        }
    }

    const getAccountCaseIdentifiers = (bankId: string) => {
        BankApi.getAccountCaseIdentifiers(bankId)
            .then(aci => {
                dispatch({
                    type: BankActionTypes.SetAccountCaseIdentifiers,
                    accountCaseIdentifiers: aci,
                });
                const today = new Date();
                const current = aci.find(a => new Date(a.activeFrom) < today && new Date(a.activeUntil) > today);
                dispatch({
                    type: BankActionTypes.SetCurrentAccountCaseIdentifier,
                    accountCaseIdentifier: current,
                });
            })
            .catch(() => {
                dispatch({
                    type: LayoutActions.AddToast,
                    toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.')),
                });
            });
    }

    const invitationConfigurationSave = (config: InvitationConfiguration) => {
        BankApi.updateInvitationConfiguration(bankId, config)
            .then(resp => {
                dispatch({
                    type: BankActionTypes.SetInvitationConfiguration,
                    configuration: resp,
                });
            })
            .catch(() => {
                dispatch({
                    type: LayoutActions.AddToast,
                    toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.')),
                });
            });
    }

    const webformConfigurationSave = (config: WebformConfiguration) => {
        BankApi.updateWebformConfiguration(bankId, config)
            .then(resp => {
                dispatch({
                    type: BankActionTypes.SetWebformConfiguration,
                    configuration: resp,
                })
            })
            .catch(() => {
                dispatch({
                    type: LayoutActions.AddToast,
                    toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.')),
                });
            });
    }

    const saveBankInfo = (updatedBank: Bank, updatedAccountCaseIdentifier?: AccountCaseIdentifier) => {
        const updateBankRequest: UpdateBankRequest = {
            id: updatedBank.id,
            name: updatedBank.name,
            inviteEmail: updatedBank.inviteEmail,
            supportEmail: updatedBank.supportEmail,
            paymentStrategies: updatedBank.paymentStrategies,
            emailTemplateKey: updatedBank.emailTemplateKey,
            accountCaseIdentifier: updatedAccountCaseIdentifier ? updatedAccountCaseIdentifier.accountCaseId : undefined,
        }
        BankApi.updateBankInfo(updatedBank.id, updateBankRequest)
            .then(resp => {
                getBank(bankId);
                getAccountCaseIdentifiers(bankId);
            })
            .catch(() => {
                dispatch({
                    type: LayoutActions.AddToast,
                    toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.')),
                });
            });
    }

    return bank ? (
        <div className={styles.page}>
            <div className={styles.content}>
                <TitleWithBack title={t('Account')} onBackClick={() => {navigateTo(RouteUrl.Invitations)}}/>
                <AccountConfigurationComponent
                    isAdmin={user?.role === UserRole.Admin}
                    initialBank={bank}
                    initialAccountCaseIdentifier={currentAccountCaseIdentifier}
                    onChanged={(updateBank, updatedAccountCaseIdentifier) => saveBankInfo(updateBank, updatedAccountCaseIdentifier)}/>
                {/*TODO rework AccountCaseIdentifiersComponent*/}
                {/*{accountCaseIdentifiers && accountCaseIdentifiers.length > 0 && user?.role === UserRole.Admin &&*/}
                {/*    <AccountCaseIdentifiersComponent*/}
                {/*        accountCaseIdentifiers={accountCaseIdentifiers}*/}
                {/*        onAddAccountCaseIdentifier={(identifier) => console.log('add')}/>}*/}
                {invitationConfiguration && user?.role === UserRole.Admin &&
                    <InvitationConfigurationComponent
                        initialInvitationConfiguration={invitationConfiguration}
                        onConfigurationChanged={(config) => invitationConfigurationSave(config)}
                        hasClientPayment={bank.paymentStrategies.includes(PaymentStrategy.ClientPayment)}/>}
                {webformConfiguration && user?.role === UserRole.Admin &&
                    <WebformConfigurationComponent
                        initialWebformConfiguration={webformConfiguration}
                        onConfigurationChanged={(config) => webformConfigurationSave(config)}/>}
                <div className={`${styles.buttons} ${styles.alignLeft}`}>
                    <button className={`${styles.button} ${styles.negative}`} onClick={() => navigateTo(RouteUrl.Invitations)}>{t('Back')}</button>
                    {hasMultipleBanks ? <button className={styles.button} onClick={() => navigateTo(RouteUrl.Root)}>{t('Switch Account')}</button> : null}
                </div>
            </div>
        </div>
    ) : null;
}

export default AccountPage;
