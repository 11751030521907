import {UserActionTypes} from './types';
import {AppAction} from '../types';
import {User} from "../../types/user";

export type UserState = {
    user?: User;
};

export const initialUserState: UserState = {
    user: undefined
};

export const userReducer = (state: UserState, action: AppAction): UserState => {
    switch (action.type) {
        case UserActionTypes.Login:
            return {user: action.user};
        case UserActionTypes.Logout:
            return {user: undefined};
        default:
            return state;
    }
};
