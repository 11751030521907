import {BankWithConfiguration} from '../../types/bank-with-configuration';
import {InvitationConfiguration} from '../../types/invitation-configuration';
import {WebformConfiguration} from '../../types/webform-configuration';
import {AccountCaseIdentifier} from "../../types/account-case-identifier";

export enum BankActionTypes {
    SetBank = 'BANK/SetBank',
    SetInvitationConfiguration = 'BANK/SetInvitationConfiguration',
    SetWebformConfiguration = 'BANK/SetWebformConfiguration',
    SetAccountCaseIdentifiers = 'BANK/SetAccountCaseIdentifiers',
    SetCurrentAccountCaseIdentifier = 'BANK/SetCurrentAccountCaseIdentifier',
}

export type BankAction =
    | { type: BankActionTypes.SetBank, bank: BankWithConfiguration | undefined }
    | { type: BankActionTypes.SetInvitationConfiguration, configuration: InvitationConfiguration }
    | { type: BankActionTypes.SetWebformConfiguration, configuration: WebformConfiguration }
    | { type: BankActionTypes.SetAccountCaseIdentifiers, accountCaseIdentifiers: AccountCaseIdentifier[] }
    | { type: BankActionTypes.SetCurrentAccountCaseIdentifier, accountCaseIdentifier: AccountCaseIdentifier | undefined };
