import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import styles from './create-account.module.scss';
import {useTranslation} from 'react-i18next';
import {useHistory} from 'react-router-dom';
import {RouteUrl} from '../../routes';
import {AppContext} from '../../store/app-context';
import {BankActionTypes} from '../../store/bank/types';
import {UserRole} from '../../types/user';
import {InvitationConfiguration} from '../../types/invitation-configuration';
import {WebformConfiguration} from '../../types/webform-configuration';
import BankApi from '../../api/bank-api';
import TitleWithBack from '../../components/title-with-back/title-with-back.component';
import {Bank, PaymentStrategy} from '../../types/bank';
import {AccountCaseIdentifier} from '../../types/account-case-identifier';
import WebformConfigurationComponent from '../account/webform-configuration.component';
import InvitationConfigurationComponent from '../account/invitation-configuration.component';
import AccountConfigurationComponent from '../account/account-configuration.component';
import {
    CreateBankRequest,
    DefaultInvitationConfiguration,
    DefaultWebformConfiguration
} from '../../types/request/create-bank-request';
import LoaderComponent from '../../components/loader/loader';
import {LayoutActions} from '../../store/layout/types';
import {ToastMessage, ToastMessageType} from '../../types/toast';

const CreateAccountPage: FunctionComponent = () => {
    const {state, dispatch} = useContext(AppContext);
    const user = state.userState.user;
    const [invitationConfiguration, setInvitationConfiguration] = useState<InvitationConfiguration>();
    const [webformConfiguration, setWebformConfiguration] = useState<WebformConfiguration>();
    const [bank, setBank] = useState<Bank>();
    const [accountCaseIdentifier, setaccountCaseIdentifier] = useState<AccountCaseIdentifier>()
    const {t} = useTranslation();
    const history = useHistory();
    const [creationInProgress, setCreationInProgress] = useState<boolean>(false)

    useEffect(() => {
        if (!user || user.role !== UserRole.Admin) {
            history.push(RouteUrl.Root);
        }
    }, [user]);

    useEffect(() => {
        const newBank: Bank = {
            id: 'new',
            name: '',
            inviteEmail: 'no-reply@stadim.be',
            supportEmail: 'supportdigital@stadim.be',
            paymentStrategies: [],
            createdOn: new Date(),
            updatedOn: new Date(),
            emailTemplateKey: '',
        }
        const newInvitationConfiguration: InvitationConfiguration = DefaultInvitationConfiguration;
        const newWebformConfiguration: WebformConfiguration = DefaultWebformConfiguration;
        setBank(newBank);
        setInvitationConfiguration(newInvitationConfiguration);
        setWebformConfiguration(newWebformConfiguration);
    }, []);

    const navigateTo = (route: RouteUrl, bankId?: string) => {
        if (route === RouteUrl.Root) {
            dispatch({type: BankActionTypes.SetBank, bank: undefined});
        } else if (bankId) {
            history.push(route.replace(':bankId', bankId));
        }
    }

    const bankInfoChange = (bank: Bank, accountCaseIdentifier?: AccountCaseIdentifier) => {
        setBank(bank);
        setaccountCaseIdentifier(accountCaseIdentifier);
    }

    const invitationConfigurationChange = (config: InvitationConfiguration) => {
        setInvitationConfiguration(config);
    }

    const webformConfigurationChange = (config: WebformConfiguration) => {
        setWebformConfiguration(config);
    }

    const createAccount = () => {
        if (bank && invitationConfiguration && webformConfiguration) {
            setCreationInProgress(true);
            let accountCaseIdentifierRequest;
            if (accountCaseIdentifier) {
                accountCaseIdentifierRequest = {
                    accountCaseId: accountCaseIdentifier.accountCaseId,
                    accountCaseName: accountCaseIdentifier.name,
                    accountCaseActiveFrom: accountCaseIdentifier.activeFrom,
                    accountCaseActiveUntil: accountCaseIdentifier.activeUntil,
                };
            }
            const createBankRequest: CreateBankRequest = {
                name: bank.name,
                paymentStrategies: bank.paymentStrategies,
                emailTemplateKey: bank.emailTemplateKey,
                inviteEmail: bank.inviteEmail,
                supportEmail: bank.supportEmail,
                accountCaseIdentifier: accountCaseIdentifierRequest,
                invitationConfiguration,
                webformConfiguration,
            }
            BankApi.createBank(createBankRequest)
                .then(createdBank => {
                    dispatch({
                        type: BankActionTypes.SetBank,
                        bank: createdBank,
                    });
                    setCreationInProgress(false);
                    navigateTo(RouteUrl.Invitations, createdBank.id);
                })
                .catch(() => {
                    dispatch({
                        type: LayoutActions.AddToast,
                        toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.')),
                    });
                });
        }
    }

    return bank && invitationConfiguration && webformConfiguration ? (
        <div className={styles.page}>
            <div className={styles.content}>
                <TitleWithBack title={t('Create Account')} onBackClick={() => {navigateTo(RouteUrl.Root)}}/>
                <AccountConfigurationComponent
                    isAdmin={user?.role === UserRole.Admin}
                    isNew={true}
                    initialBank={bank}
                    initialAccountCaseIdentifier={accountCaseIdentifier}
                    onChanged={(updateBank, updatedAccountCaseIdentifier) => bankInfoChange(updateBank, updatedAccountCaseIdentifier)}/>
                <InvitationConfigurationComponent
                    isNew={true}
                    initialInvitationConfiguration={invitationConfiguration}
                    onConfigurationChanged={(config) => invitationConfigurationChange(config)}
                    hasClientPayment={bank.paymentStrategies.includes(PaymentStrategy.ClientPayment)}/>
                <WebformConfigurationComponent
                    isNew={true}
                    initialWebformConfiguration={webformConfiguration}
                    onConfigurationChanged={(config) => webformConfigurationChange(config)}/>
                <div className={`${styles.buttons} ${styles.alignLeft}`}>
                    <button className={`${styles.button} ${styles.negative}`} onClick={() => navigateTo(RouteUrl.Root)}>{t('Cancel')}</button>
                    <button className={styles.button} onClick={() => createAccount()}>{creationInProgress ? <LoaderComponent/> : t('Create Account')}</button>
                </div>
            </div>
        </div>
    ) : <></>;
}

export default CreateAccountPage;
