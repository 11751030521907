import {LoanPurpose, TransactionType} from './request/invitation-request';
import {PaymentStrategy} from './bank';

export interface Invitation {
    id: string;
    token: string;
    firstName: string;
    lastName: string;
    email?: string;
    address?: string;
    status: InvitationStatus;
    externalRef?: string;
    createdOn: Date;
    updatedOn: Date;
    fullVisitValuation?: boolean;
    loanPurpose?: LoanPurpose;
    transactionValue?: number;
    transactionType?: TransactionType;
    transactionYear?: number;
    renovationBudget?: number;
    createdBy: string;
    paymentStrategy: PaymentStrategy;
    fullPropertyRights?: boolean;
    language: Language;
    isArchived: boolean;
    valuationNeeded: boolean;
    secondaryEmail?: string;
    nsxId?: string;
    realEstateType: RealEstateType;
    extraInformation: string;
}

export enum InvitationStatus {
    PENDING = 'PENDING',
    COMPLETED = 'COMPLETED',
    PROCESSED = 'PROCESSED',
}

// t('EN')
// t('NL')
// t('FR')
export enum Language {
    EN = 'EN',
    NL = 'NL',
    FR = 'FR'
}

export enum RealEstateType {
    RRE = 'RRE', // Residential
    CRE = 'CRE', // Commercial
}
