import {RuntimeConfig} from '../../config/runtime-config';

export enum ApiUrls {
    // USERS
    GetAllUsers = '/admin/users',
    GetCurrentUser = '/admin/users/current',
    GetUserByUsername = '/admin/users/user/{username}',
    GetUsersForBank = '/admin/banks/{bankId}/users/search',
    CreateUser = '/admin/banks/{bankId}/users/create',
    DeleteUser = '/admin/banks/{bankId}/users/{userId}/delete',
    LinkUserToBank = '/admin/banks/{bankId}/link',

    // BANKS
    CreateBank = '/admin/banks',
    Bank = '/admin/banks/{bankId}',
    UpdateInvitationConfiguration = '/admin/banks/{bankId}/invitation-configuration',
    UpdateWebformConfiguration = '/admin/banks/{bankId}/webform-configuration',
    AccountCaseIdentifiers = '/admin/banks/{bankId}/account-case-identifiers',
    BankFiles = '/admin/banks/{bankId}/files',

    // INVITATIONS
    PostBankInvitation = '/admin/banks/{bankId}/invite',
    PostBankInvitationBulk = '/admin/banks/{bankId}/invite-bulk',
    GetBankInvitations = '/admin/banks/{bankId}/invitations/search',
    GetBankInvitation = '/admin/banks/{bankId}/invitations/{invitationId}',
    ResendInvitation = '/admin/banks/{bankId}/invitations/{invitationId}/resend',
    GetBankInvitationFiles = '/admin/banks/{bankId}/invitations/{invitationToken}/files',
    ToggleBankInvitationStatus = '/admin/banks/{bankId}/invitations/{invitationId}/status',
    DeleteInvitationFromBank = '/admin/banks/{bankId}/invitations/{invitationId}/delete',
}

export function buildBaseUrl(): string {
    const apiUrl = RuntimeConfig.getApiURL();
    return `${apiUrl}`;
}

export class UrlParamBuilder {
    private params: any = {};

    public addBankId(id: string): UrlParamBuilder {
        this.params['bankId'] = id;
        return this;
    }

    public addUsername(username: string): UrlParamBuilder {
        this.params['username'] = username;
        return this;
    }

    public addInvitationToken(invitationToken: string): UrlParamBuilder {
        this.params['invitationToken'] = invitationToken;
        return this;
    }

    public addInvitationId(invitationId: string): UrlParamBuilder {
        this.params['invitationId'] = invitationId;
        return this;
    }

    public addUserId(userId: string): UrlParamBuilder {
        this.params['userId'] = userId;
        return this;
    }

    public build(): any {
        return this.params;
    }
}
