import {User} from "../../types/user";

export enum UserActionTypes {
    Login = 'USER/login',
    Logout = 'USER/logout',
}

export type UserAction =
    | { type: UserActionTypes.Login, user: User }
    | { type: UserActionTypes.Logout };
