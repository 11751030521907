import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import styles from './user.module.scss';
import {AppContext} from '../../store/app-context';
import {useMsal} from '@azure/msal-react';
import {AccountInfo} from '@azure/msal-browser';
import UserApi from '../../api/user-api';
import {conditionalClassLister} from '../../utils/class-helper';
import {useTranslation} from 'react-i18next';
import {RouteUrl} from '../../routes';
import {UserActionTypes} from '../../store/user/types';
import {LayoutActions} from '../../store/layout/types';
import {ToastMessage, ToastMessageType} from '../../types/toast';
import {userErrors} from '../../types/error';
import {ErrorActionTypes} from '../../store/error/types';
import {User, UserRole} from '../../types/user';

const UserComponent: FunctionComponent = () => {
    const {instance, accounts, inProgress} = useMsal();
    const { state, dispatch } = useContext(AppContext);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const {t} = useTranslation();
    const history = useHistory();
    const [bankId, setBankId] = useState<string>();
    let accountInfo = instance.getActiveAccount() as AccountInfo;

    const blurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        setShowDropdown(false);
    }

    useEffect(() => {
        setBankId(state.bankState.bank?.id);
        if (accounts.length > 0) {
            instance.setActiveAccount(accounts[0]);
            accountInfo = instance.getActiveAccount() as AccountInfo;
        }
        if (!state.userState.user) {
            UserApi.getCurrentUser()
                .then((user) => {
                    dispatch({type: UserActionTypes.Login, user: user as User})
                })
                .catch((err) => {
                    if (err.response && err.response.data && userErrors.find(e => e === err.response.data.type)) {
                        dispatch({type: ErrorActionTypes.SetError, error: err.response.data});
                        navigateTo(RouteUrl.Error);
                    } else {
                        dispatch({
                            type: LayoutActions.AddToast,
                            toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.')),
                        });
                    }
                });
        }
    }, [inProgress, accounts, instance, state.bankState.bank]);

    const logout = () => {
        instance.logout();
    }

    const onToggleDropdownClick = () => {
        setShowDropdown(!showDropdown);
    };

    const dropDownClasses = conditionalClassLister(styles)({
        dropdown: true,
        hidden: !showDropdown
    });

    const navigateTo = (route: RouteUrl) => {
        const currentBankId = bankId || state.bankState.bank?.id;
        history.push(route.replace(':bankId', currentBankId!));
        setShowDropdown(!showDropdown);
    }

    return (
        <div>
            <div className={styles.userButton}
                 onClick={() => onToggleDropdownClick()}
                 onBlur={blurHandler}
                 tabIndex={0}
            >
                <div className={!state.bankState.bank ? styles.userAccount : undefined}>{accountInfo ? accountInfo.name : ''}</div>
                {state.bankState.bank ? <div className={styles.userBank}>{state.bankState.bank.name}</div> : null}
            </div>
            <div className={dropDownClasses}>
                {bankId && state.bankState.bank && <div className={styles.dropdownLink} onMouseDown={() => navigateTo(RouteUrl.Account)}>{t('Account')}</div>}
                {state.userState.user?.role === UserRole.Admin && <div className={styles.dropdownLink} onMouseDown={() => navigateTo(RouteUrl.CreateAccount)}>{t('Create Account')}</div>}
                <div className={styles.dropdownLink} onMouseDown={() => logout()}>{t('Logout')}</div>
            </div>
        </div>
    );
};

export default UserComponent;

