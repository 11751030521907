import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import styles from './error.module.scss';
import {AppContext} from '../../store/app-context';
import {ReactComponent as NotFoundIcon} from '../../../assets/images/404.svg';
import {ReactComponent as ErrorIcon} from '../../../assets/images/error.svg';
import {LayoutActions} from '../../store/layout/types';

const ErrorPage: FunctionComponent = () => {
    const {state, dispatch} = useContext(AppContext);
    const [error, setError] = useState(state.errorState.error);
    const {t} = useTranslation();

    useEffect(() => {
        dispatch({type: LayoutActions.HideHeader});
        setError(state.errorState.error);
    }, [state.errorState.error]);

    return (
        <div className={styles.page}>
            {error ?
                <div className={styles.container}>
                    <div className={styles.imgContainer}>
                        <ErrorIcon className={styles.img} />
                    </div>
                    <div className={styles.textContainer}>
                        <h1>{t('An error occurred')}</h1>
                        <p>{t('There was an error with your user configuration:')} {error.message}</p>
                        <p>{t('Error correlation ID:')} {error.correlationID}</p>
                        <p>{t('Please contact an administrator.')}</p>
                    </div>
                </div>
                :
                <div className={styles.container}>
                    <div className={styles.imgContainer}>
                        <NotFoundIcon className={styles.img}/>
                    </div>
                    <div className={styles.textContainer}>
                        <h1>{t('Page not found')}</h1>
                        <p>{t('The page you are looking for does not exist or an other error occurred.')}</p>
                    </div>
                </div>
            }
        </div>
    )
}

export default ErrorPage;
