import React, {
	ChangeEvent,
	FunctionComponent,
	useContext,
	useEffect,
	useState,
} from 'react';
import { InvitationConfiguration } from '../../types/invitation-configuration';
import styles from './configuration.module.scss';
import { useTranslation } from 'react-i18next';
import { VariableDisplay } from '../../types/variable-display';
import ConfigDropdownComponent from './config-dropdown.component';
import ReactTooltip from 'react-tooltip';
import exclamationMarkIcon from '../../../assets/images/icon_Exclamation Mark.svg';
import { enumToSelectionOptions } from '../../components/dropdown/selection-option.util';
import { PaymentStrategy } from '../../types/bank';
import { AppContext } from '../../store/app-context';
import { conditionalClassLister } from '../../utils/class-helper';
import { SelectionOption } from '../../components/dropdown/dropdown.component';
import ConfigToggleSwitchComponent from './config-toggle-switch.component';

const InvitationConfigurationComponent: FunctionComponent<OwnProps> = ({
	isNew = false,
	initialInvitationConfiguration,
	onConfigurationChanged,
	hasClientPayment,
}: OwnProps) => {
	const { t } = useTranslation();
	const [editMode, setEditMode] = useState<boolean>(false);
	const [configuration, setConfiguration] = useState<InvitationConfiguration>(
		initialInvitationConfiguration
	);
	const [error, setError] = useState<string>();
	const [paymentStrategies, setPaymentStrategies] =
		useState<SelectionOption[]>();

	const appContext = useContext(AppContext);

	const { state } = appContext;

	const variableDisplayOptions = enumToSelectionOptions(VariableDisplay, t);
	const paymentStrategyOptions = enumToSelectionOptions(PaymentStrategy, t);

	useEffect(() => {
		if (!state.bankState.bank) {
			const possiblePaymentStrategies = Object.values(PaymentStrategy).map(
				strategy => ({
					key: strategy,
					value: strategy,
					label: t(strategy),
				})
			);

			setPaymentStrategies(possiblePaymentStrategies);
		} else {
			const bankSpecificPaymentStrategies =
				state.bankState.bank.paymentStrategies.map(strategy => ({
					key: strategy,
					value: strategy,
					label: t(strategy),
				}));

			if (bankSpecificPaymentStrategies?.length > 0) {
				setPaymentStrategies(bankSpecificPaymentStrategies);
			}
		}
	}, [state.bankState.bank]);

	useEffect(() => {
		if (isNew) {
			setEditMode(true);
		}
	}, [isNew]);

	useEffect(() => {
		if (isNew) {
			onConfigurationChanged(configuration);
		}
	}, [configuration]);

	useEffect(() => {
		if (initialInvitationConfiguration) {
			setConfiguration(initialInvitationConfiguration);
		}
	}, [initialInvitationConfiguration]);

	useEffect(() => {
		if (hasClientPayment) {
			if (
				configuration.displayLoanPurpose !== VariableDisplay.Required ||
				configuration.displayTransactionValue !== VariableDisplay.Required ||
				configuration.displayFullPropertyRights !== VariableDisplay.Required ||
				configuration.displayFullVisitValuation !== VariableDisplay.Required
			) {
				setError(
					t(
						'Loan purpose, transaction value, full property rights & full visit valuation are all required when ClientPayment is available!'
					)
				);
			} else {
				setError('');
			}
		}
	}, [
		hasClientPayment,
		configuration.displayLoanPurpose,
		configuration.displayTransactionValue,
		configuration.displayFullPropertyRights,
		configuration.displayFullVisitValuation,
	]);

	const onSave = () => {
		if (!error) {
			onConfigurationChanged(configuration);
			setEditMode(false);
		}
	};

	const onCancel = () => {
		setConfiguration(initialInvitationConfiguration);
		setEditMode(false);
	};

	const onDisplayEmailChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayEmail = value;
		setConfiguration(configurationCopy);
	};

	const onDisplaySecondaryEmailChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displaySecondaryEmail = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayFullPropertyRightsChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayFullPropertyRights = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayFullVisitValuationChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayFullVisitValuation = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayLoanPurposeChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayLoanPurpose = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayTransactionValueChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayTransactionValue = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayTransactionTypeChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayTransactionType = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayTransactionYearChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayTransactionYear = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayRenovationBudgetChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayRenovationBudget = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayNsxIdChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayNsxId = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayValuationNeededChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayValuationNeeded = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayPaymentStrategyChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayPaymentStrategy = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayReferenceChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayReference = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayAddressChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayAddress = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayClusterChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayCluster = value;
		setConfiguration(configurationCopy);
	};

	const onDisplayRealEstateTypeChanged = (value: VariableDisplay) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayRealEstateType = value;
		setConfiguration(configurationCopy);
	};

	const onDefaultPaymentStrategyChanged = (value: string) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.defaultPaymentStrategy = value as PaymentStrategy;
		setConfiguration(configurationCopy);
	};
	const onDisplayedWarningChanged = (e: ChangeEvent<HTMLInputElement>) => {
		const configurationCopy = Object.assign({}, configuration);
		configurationCopy.displayWarning = e.target.checked;

		setConfiguration(configurationCopy);
	};

	return (
		<div className={styles.configurationPanel}>
			<div className={styles.panelTitle}>
				<div>
					{t('Invitation Configuration')}
					{error && (
						<div className={styles.invalid}>
							<ReactTooltip effect="solid" />
							<img src={exclamationMarkIcon} data-tip={error} />
						</div>
					)}
				</div>
				{!isNew && !editMode && (
					<div className={styles.buttons}>
						<button className={styles.button} onClick={() => setEditMode(true)}>
							{t('Edit')}
						</button>
					</div>
				)}
				{!isNew && editMode && (
					<div className={styles.buttons}>
						<button
							className={`${styles.button} ${styles.negative}`}
							onClick={() => onCancel()}
						>
							{t('Cancel')}
						</button>
						<button
							className={conditionalClassLister(styles)({
								button: true,
								disabled: !!error,
							})}
							onClick={() => onSave()}
						>
							{t('Save')}
						</button>
					</div>
				)}
			</div>

			<ConfigToggleSwitchComponent
				label={t('Show warning on invitation page')}
				value={configuration.displayWarning}
				onChange={onDisplayedWarningChanged}
				id="toggle_switch_1"
				editMode={editMode}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Address')}
				editMode={editMode}
				value={configuration.displayAddress}
				onValueChanged={value => onDisplayAddressChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Reference')}
				editMode={editMode}
				value={configuration.displayReference}
				onValueChanged={value => onDisplayReferenceChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Email')}
				editMode={editMode}
				value={configuration.displayEmail}
				infoText={t(
					'If email is not required, name, language & payment strategies will also be unavailable.'
				)}
				onValueChanged={value => onDisplayEmailChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Secondary email')}
				editMode={editMode}
				value={configuration.displaySecondaryEmail}
				onValueChanged={value => onDisplaySecondaryEmailChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Full property rights')}
				editMode={editMode}
				value={configuration.displayFullPropertyRights}
				onValueChanged={value => onDisplayFullPropertyRightsChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Full visit valuation')}
				editMode={editMode}
				value={configuration.displayFullVisitValuation}
				onValueChanged={value => onDisplayFullVisitValuationChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Loan purpose')}
				editMode={editMode}
				value={configuration.displayLoanPurpose}
				onValueChanged={value => onDisplayLoanPurposeChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Transaction value')}
				editMode={editMode}
				value={configuration.displayTransactionValue}
				onValueChanged={value => onDisplayTransactionValueChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Transaction type')}
				editMode={editMode}
				value={configuration.displayTransactionType}
				onValueChanged={value => onDisplayTransactionTypeChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Transaction year')}
				editMode={editMode}
				value={configuration.displayTransactionYear}
				onValueChanged={value => onDisplayTransactionYearChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Renovation budget')}
				editMode={editMode}
				value={configuration.displayRenovationBudget}
				onValueChanged={value => onDisplayRenovationBudgetChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('NSX Id')}
				editMode={editMode}
				value={configuration.displayNsxId}
				onValueChanged={value => onDisplayNsxIdChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Valuation needed')}
				editMode={editMode}
				value={configuration.displayValuationNeeded}
				infoText={t(`Currently always set to 'yes'`)}
				onValueChanged={value => onDisplayValuationNeededChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Cluster/Zone')}
				editMode={editMode}
				value={configuration.displayCluster}
				onValueChanged={value => onDisplayClusterChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Real estate type')}
				editMode={editMode}
				value={configuration.displayRealEstateType}
				onValueChanged={value => onDisplayRealEstateTypeChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			<div className={styles.panelTitle}>Payment configutation</div>
			<ConfigDropdownComponent<VariableDisplay>
				label={t('Payment strategy')}
				editMode={editMode}
				value={configuration.displayPaymentStrategy}
				onValueChanged={value => onDisplayPaymentStrategyChanged(value)}
				selectionOptions={variableDisplayOptions}
			/>
			{paymentStrategies && paymentStrategies?.length > 1 && (
				<ConfigDropdownComponent<PaymentStrategy>
					label={t('Default Payment Strategy')}
					editMode={editMode}
					value={configuration.defaultPaymentStrategy}
					onValueChanged={value => onDefaultPaymentStrategyChanged(value)}
					selectionOptions={paymentStrategyOptions}
				/>
			)}
		</div>
	);
};
export default InvitationConfigurationComponent;

type OwnProps = {
	isNew?: boolean;
	initialInvitationConfiguration: InvitationConfiguration;
	onConfigurationChanged: (config: InvitationConfiguration) => void;
	hasClientPayment: boolean;
};
