import {VariableDisplay} from '../variable-display';
import {PaymentStrategy} from '../bank';

export interface CreateBankRequest {
    name: string;
    paymentStrategies: PaymentStrategy[];
    emailTemplateKey: string;
    inviteEmail?: string;
    supportEmail?: string;
    accountCaseIdentifier?: CreateAccountCaseIdentifierRequest;
    invitationConfiguration: CreateInvitationConfigurationRequest;
    webformConfiguration: CreateWebformConfigurationRequest;
}

export interface CreateAccountCaseIdentifierRequest {
    accountCaseId: string;
    accountCaseName: string;
    accountCaseActiveFrom: Date;
    accountCaseActiveUntil: Date;
}

export interface CreateInvitationConfigurationRequest {
    displayLoanPurpose: VariableDisplay;
    displayFullVisitValuation: VariableDisplay;
    displayFullPropertyRights: VariableDisplay;
    displayTransactionValue: VariableDisplay;
    displayTransactionType: VariableDisplay;
    displayTransactionYear: VariableDisplay;
    displayRenovationBudget: VariableDisplay;
    displayNsxId: VariableDisplay;
    displaySecondaryEmail: VariableDisplay;
    displayEmail: VariableDisplay;
    displayValuationNeeded: VariableDisplay;
    displayPaymentStrategy: VariableDisplay;
    displayReference: VariableDisplay;
    displayCluster: VariableDisplay;
    displayRealEstateType: VariableDisplay;
    defaultPaymentStrategy: PaymentStrategy;
    displayWarning: boolean;
    displayAddress: VariableDisplay
}

export interface CreateWebformConfigurationRequest {
    displayStorageBasementSize: VariableDisplay;
    displayStorageAtticSize: VariableDisplay;
    displayBalconyAndTerraceSize: VariableDisplay;
    displayPrivateGardenSize: VariableDisplay;
    displayCommunalGardenSize: VariableDisplay;
    displayPartialRenovation: VariableDisplay;
    displayOverallRenovationWithPermit: VariableDisplay;
    displayLastValuationPrice: VariableDisplay;
    displayLastValuationDate: VariableDisplay;
    displayPlansUpload: VariableDisplay;
    displayEpcReportUpload: VariableDisplay;
    displayAmenitiesImagesUpload: VariableDisplay;
    displayBuildingImagesUpload: VariableDisplay;
    displayBuildingPermitUpload: VariableDisplay;
    displayValuationReportUpload: VariableDisplay;
    displayBuiltInFurniture: VariableDisplay;
    displayTransactionDate: VariableDisplay;
    displayTransactionPrice: VariableDisplay;
    displayEpc: VariableDisplay;
}

export const DefaultInvitationConfiguration: CreateInvitationConfigurationRequest =
    {
        displayEmail: VariableDisplay.Required,
        displayLoanPurpose: VariableDisplay.Required,
        displayFullVisitValuation: VariableDisplay.Required,
        displayFullPropertyRights: VariableDisplay.Required,
        displayTransactionValue: VariableDisplay.Required,
        displayTransactionType: VariableDisplay.Hidden,
        displayTransactionYear: VariableDisplay.Required,
        displayRenovationBudget: VariableDisplay.Optional,
        displayNsxId: VariableDisplay.Hidden,
        displaySecondaryEmail: VariableDisplay.Hidden,
        displayValuationNeeded: VariableDisplay.Required,
        displayPaymentStrategy: VariableDisplay.Required,
        displayReference: VariableDisplay.Optional,
        displayCluster: VariableDisplay.Hidden,
        displayRealEstateType: VariableDisplay.Hidden,
        defaultPaymentStrategy: PaymentStrategy.ClientPayment,
        displayWarning: false,
        displayAddress: VariableDisplay.Optional
    }

export const DefaultWebformConfiguration: CreateWebformConfigurationRequest = {
    displayStorageBasementSize: VariableDisplay.Required,
    displayStorageAtticSize: VariableDisplay.Required,
    displayBalconyAndTerraceSize: VariableDisplay.Optional,
    displayPrivateGardenSize: VariableDisplay.Optional,
    displayCommunalGardenSize: VariableDisplay.Optional,
    displayPartialRenovation: VariableDisplay.Optional,
    displayOverallRenovationWithPermit: VariableDisplay.Optional,
    displayLastValuationPrice: VariableDisplay.Optional,
    displayLastValuationDate: VariableDisplay.Optional,
    displayPlansUpload: VariableDisplay.Optional,
    displayEpcReportUpload: VariableDisplay.Optional,
    displayAmenitiesImagesUpload: VariableDisplay.Required,
    displayBuildingImagesUpload: VariableDisplay.Required,
    displayBuildingPermitUpload: VariableDisplay.Optional,
    displayValuationReportUpload: VariableDisplay.Optional,
    displayBuiltInFurniture: VariableDisplay.Required,
    displayTransactionDate: VariableDisplay.Optional,
    displayTransactionPrice: VariableDisplay.Optional,
    displayEpc: VariableDisplay.Optional,
};
