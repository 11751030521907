import React, {FunctionComponent, useContext} from 'react';
import styles from './title-with-back.component.module.scss';
import {useTranslation} from 'react-i18next';
import {ReactComponent as BackIconComponent} from '../../../assets/images/back.svg';

const TitleWithBack: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {t} = useTranslation();
    const {title, onBackClick} = props;

    return (
        <div className={styles.container}>
            <div className={styles.title}>{ title }</div>
            <div className={styles.link} onClick={() => onBackClick()}>
                <BackIconComponent className={styles.icon}></BackIconComponent>{t('Back')}</div>
        </div>
    )
}

export default TitleWithBack;

interface OwnProps {
    title: string;
    onBackClick: () => void;
}
