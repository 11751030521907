import {Http} from '../http/http';
import {ApiUrls, UrlParamBuilder} from './api-urls';
import {User, UserRole} from '../types/user';

class UserApi {
    public static async getAllUsers(): Promise<User[]> {
        return await Http.get(ApiUrls.GetAllUsers);
    }

    public static async getCurrentUser(): Promise<User> {
        return await Http.get(
            ApiUrls.GetCurrentUser,
        );
    }

    public static async createUser(email: string, role: UserRole, bankId: string): Promise<void> {
        const body = {
            username: email,
            role
        }
        return await Http.post(
            ApiUrls.CreateUser,
            body,
            new UrlParamBuilder().addBankId(bankId).build()
        );
    }

    public static async getUserByUsername(email: string): Promise<User> {
        return await Http.get(
            ApiUrls.GetUserByUsername,
            new UrlParamBuilder().addUsername(email).build(),
        );
    }

    public static async linkUserToBank(userId: string, bankId: string): Promise<void> {
        const body = {
            userId
        }
        return await Http.put(
            ApiUrls.LinkUserToBank,
            body,
            new UrlParamBuilder().addBankId(bankId).build()
        )
    }

    public static async deleteUser(bankId: string, userId: string): Promise<User> {
        return await Http.put(
            ApiUrls.DeleteUser,
            {},
            new UrlParamBuilder().addBankId(bankId).addUserId(userId).build(),
        );
    }



}

export default UserApi;
