import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import ToggleSwitchComponent from '../../components/checkbox/toggle-switch.component';
import styles from './config-field.module.scss';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as Info } from '../../../assets/images/info.svg';

const ConfigToggleSwitchComponent: React.FC<OwnProps> = ({
	editMode,
	label,
	infoText,
	value,
	onChange,
	id,
}) => {
	const { t } = useTranslation();
	return (
		<div className={styles.configurationField}>
			<div className={styles.label}>
				{label}:
				{editMode && infoText && (
					<div className={styles.infoIconBox}>
						<ReactTooltip id={id} effect="solid" place="right" />
						<Info
							className={styles.infoIcon}
							data-tip={infoText}
							data-for={id}
						/>
					</div>
				)}
			</div>
			<ToggleSwitchComponent
				isChecked={value}
				name={id}
				id={id}
				onChange={onChange}
				disabled={!editMode}
			/>
		</div>
	);
};

export default ConfigToggleSwitchComponent;

interface OwnProps {
	id: string;
	editMode: boolean;
	label: string;
	infoText?: string;
	value: boolean;
	onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}
