import {PopupAction, PopupActionTypes, PopupState} from './types';
import {AppAction} from "../types";

export const initialPopupState: PopupState = {
    popup: undefined,
};

export const popupReducer  = (state = initialPopupState, action: AppAction): PopupState => {
    switch (action.type) {
        case PopupActionTypes.ShowPopup: {
            return {...state, popup: action.popup};
        }
        case PopupActionTypes.ClosePopup: {
            return {...state, popup: undefined};
        }
        default: {
            return state;
        }
    }
};
