import React, {FunctionComponent} from 'react';
import {SelectionOption} from '../dropdown/dropdown.component';
import styles from './multi-select.module.scss';
import {ReactComponent as CheckboxComponent} from '../../../assets/images/checkbox.svg';
import {conditionalClassLister} from "../../utils/class-helper";

const MultiSelectComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {options, onSelectionsChanged, selection, allowMultiSelect = true} = props;

    const select = (option: SelectionOption, selected: boolean) => {
        let result: SelectionOption[];
        if (allowMultiSelect) {
            result = [...selection];
            if (selected) {
                const index = result.findIndex(opt => opt.value === option.value);
                result.splice(index, 1);
            } else {
                result.push(option);
            }
        } else {
            result = [option];
        }

        onSelectionsChanged(result);
    }

    return (
        <div className={styles.content}>
            {options.map(option => {
                const selected = !!selection.find(o => option.value === o.value);
                const key = option.key ? option.key : option.value;
                const buttonClasses = conditionalClassLister(styles)({
                    label: true,
                    selected,
                });
                return <div className={buttonClasses}
                            key={key}
                            onClick={() => select(option, selected)}>
                    <div className={styles.checkBoxPanel}>
                        <CheckboxComponent className={styles.checkIcon} />
                    </div>
                    {option.label}
                </div>
            })}
        </div>
    );
}

export default MultiSelectComponent;

interface OwnProps {
    options: SelectionOption[];
    onSelectionsChanged: (selections: SelectionOption[]) => void;
    selection: SelectionOption[];
    allowMultiSelect?: boolean;
}

