import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import styles from './invitations.module.scss';
import {useTranslation} from 'react-i18next';
import BankApi from '../../api/bank-api';
import {useHistory, useParams} from 'react-router-dom';
import {RouteUrl} from '../../routes';
import {AdminClientParams} from '../../types/admin-client-params';
import {saveAs} from 'file-saver';
import LoaderComponent from '../../components/loader/loader';
import {AppContext} from '../../store/app-context';
import {ReactComponent as SearchIconComponent} from '../../../assets/images/search.svg';
import {InvitationsRequest} from '../../types/request/invitations-request';
import {InvitationSortOrder} from '../../types/invitation-sort-order';
import {InvitationSortOrderMapper} from '../../utils/invitation-sort-order.mapper';
import {
    enumToSelectionOptions,
    enumToSelectionOptionsWithExcluding
} from '../../components/dropdown/selection-option.util';
import DropdownComponent, {SelectionOption} from '../../components/dropdown/dropdown.component';
import {InvitationActionTypes} from '../../store/invitation/types';
import {Invitation, InvitationStatus, RealEstateType} from '../../types/invitation';
import PagingComponent from '../../components/paging/paging.component';
import {PopupActionTypes, PopupSize} from '../../store/popup/types';
import ConfirmationPopupComponent from '../../components/confirm-delete-popup/confirmation-popup.component';
import {LayoutActions} from '../../store/layout/types';
import {ToastMessage, ToastMessageType} from '../../types/toast';
import {UserRole} from '../../types/user';
import {ReactComponent as TrashIconComponent} from '../../../assets/images/trash.svg';
import {ReactComponent as EyeIconComponent} from '../../../assets/images/eye-solid.svg';
import {ReactComponent as EyeSlashIconComponent} from '../../../assets/images/eye-slash-solid.svg';
import {ReactComponent as PaperPlaneIconComponent} from '../../../assets/images/paper-plane.svg';
import {VariableDisplay} from '../../types/variable-display';
import ReactTooltip from 'react-tooltip';
import {VanBredaBankId} from '../../constants/bank-ids';

const InvitationsPage: FunctionComponent = () => {
    const {state, dispatch} = useContext(AppContext);
    const [invitations, setInvitations] = useState<Invitation[]>([]);
    const {t} = useTranslation();
    const history = useHistory();
    const {bankId} = useParams<AdminClientParams>();
    const [downloadIds, setDownloadIds] = useState<string[]>([]);
    const [inProgress, setInProgress] = useState<boolean>(true);
    const [filter, setFilter] = useState<string>('');
    const [page, setPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(50);
    const [totalItems, setTotalItems] = useState<number>(0);
    const [sort, setSort] = useState<InvitationSortOrder>(InvitationSortOrder.UpdatedOnDescending);
    const [sortOptions, setSortOptions] = useState<SelectionOption[]>([]);
    const [searchText, setSearchText] = useState<string>('');
    const configuration = state.bankState.invitationConfiguration;
    const [viewAll, setViewAll] = useState<boolean>(state.userState.user?.role === UserRole.Admin);

    useEffect(() => {
        if (!bankId || !state.bankState.bank) {
            history.push(RouteUrl.Root);
        } else {
            getInvitations(bankId);
        }
    }, [bankId, sort, page, itemsPerPage, viewAll]);

    useEffect(() => {
        if (configuration && configuration.displayEmail === VariableDisplay.Hidden) {
            const options = enumToSelectionOptionsWithExcluding(InvitationSortOrder, t, [InvitationSortOrder.EmailDescending, InvitationSortOrder.EmailAscending]);
            setSortOptions(options);
            setSearchText(t('Search by address'));
        } else {
            const options = enumToSelectionOptions(InvitationSortOrder, t);
            setSortOptions(options);
            setSearchText(t('Search by email or address'));
        }
    }, [configuration]);

    const getInvitations = async (bankId: string) => {
        setInProgress(true);
        setInvitations([]);
        const request: InvitationsRequest = {
            sorting: InvitationSortOrderMapper.mapToSortingOptions(sort),
            filter: filter.trim(),
            showPendingOnly: !viewAll,
            page,
            itemsPerPage,
        }
        const result = await BankApi.getInvitationsForBank(bankId, request);
        setInvitations(result.items);
        setTotalItems(result.count);
        setInProgress(false);
    }

    const navigateTo = (route: RouteUrl) => {
        history.push(route.replace(':bankId', bankId));
    }

    const downloadFiles = async (event: React.MouseEvent<HTMLSpanElement>, invitationToken: string) => {
        event.stopPropagation();
        if (invitationToken) {
            let ids = [...downloadIds];
            ids.push(invitationToken);
            setDownloadIds(ids);
            const response = await BankApi.getFiles(bankId, invitationToken);
            saveAs(response, `invitation-${new Date().getTime()}`);
            ids = [...downloadIds];
            const index = ids.indexOf(invitationToken);
            ids.splice(index, 1);
            setDownloadIds(ids);
        }
    }

    const toggleProcessed = async (event: React.MouseEvent<HTMLSpanElement>, invitationId: string) => {
        event.stopPropagation();
        if (invitationId) {
            const result = await BankApi.toggleProcessedStatus(bankId, invitationId);
            if (result) {
                const updated = [...invitations];
                updated.find(invitation => invitation.id === invitationId)!.status = result.status;
                setInvitations(updated);
            }
        }
    }

    const handleKeyPress = (event: any) => {
        if (event.code === 'Enter') {
            setPage(1);
            getInvitations(bankId);
        }
    }

    const onSortOptionChanged = (optionValue: string) => {
        setSort(InvitationSortOrder[optionValue as keyof typeof InvitationSortOrder]);
    };

    const onPreviousPage = () => {
        setPage(page - 1);
    };

    const onNextPage = () => {
        setPage(page + 1);
    };

    const onJumpToPage = (index: number) => {
        if (!isNaN(index)) {
            setPage(index);
        }
    };

    const onItemsPerPageChanged = (amount: number) => {
        setPage(1);
        setItemsPerPage(amount);
    };

    const openInvitation = (invitation: Invitation) => {
        dispatch({type: InvitationActionTypes.SetInvitation, invitation});
        history.push(RouteUrl.InvitationDetails.replace(':bankId', bankId).replace(':invitationId', invitation.id));
    }

    const openConfirmDeletePopup = (event: React.MouseEvent<HTMLSpanElement>, invitation: Invitation) => {
        event.stopPropagation();
        let message;
        if (configuration?.displayEmail === VariableDisplay.Hidden) {
            message = t('Are you sure you want to delete the invitation for {{address}}?', {address: invitation.address})
        } else {
            message = t('Are you sure you want to delete the invitation for {{email}}?', {email: invitation.email});
        }
        dispatch({
            type: PopupActionTypes.ShowPopup,
            popup: {
                content:
                    <ConfirmationPopupComponent
                        title={t('Confirm deletion')}
                        message={message}
                        callback={() => handleInvitationDelete(invitation.id)}
                    />,
                size: PopupSize.Small,
            },
        });
    }

    const openConfirmResendPopup = (event: React.MouseEvent<HTMLSpanElement>, invitation: Invitation) => {
        event.stopPropagation();
        if (invitation.email) {
            dispatch({
                type: PopupActionTypes.ShowPopup,
                popup: {
                    content:
                        <ConfirmationPopupComponent
                            title={t('Resend invitation')}
                            message={t('Are you sure you want to resend the invitation for {{email}}?', {email: invitation.email})}
                            callback={() => handleInvitationResend(invitation.id)}
                        />,
                    size: PopupSize.Small,
                },
            });
        }
    }

    const handleInvitationDelete = async (invitationId: string) => {
        await BankApi.deleteInvitation(bankId, invitationId).then(async (response) => {
            await getInvitations(bankId);
            dispatch({
                type: PopupActionTypes.ClosePopup,
            });
            dispatch({
                type: LayoutActions.AddToast,
                toast: new ToastMessage(ToastMessageType.Success, t('Invitation deleted successfully')),
            });
        }).catch((err) => {
            dispatch({
                type: LayoutActions.AddToast,
                toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.')),
            });
        });
    }

    const handleInvitationResend = async (invitationId: string) => {
        await BankApi.resendInvitation(bankId, invitationId).then(async (response) => {
            dispatch({
                type: PopupActionTypes.ClosePopup,
            });
            dispatch({
                type: LayoutActions.AddToast,
                toast: new ToastMessage(ToastMessageType.Success, t('Invitation resend successfully')),
            });
        }).catch((err) => {
            dispatch({
                type: LayoutActions.AddToast,
                toast: new ToastMessage(ToastMessageType.Error, t('Something went wrong, please try again.')),
            });
        });
    }

    const canToggleProcessed = (invitation: Invitation) => {
        switch (invitation.realEstateType) {
            case RealEstateType.RRE:
                return invitation.status === InvitationStatus.COMPLETED;
            case RealEstateType.CRE:
                return invitation.status === InvitationStatus.PENDING;
        }
    }

    const canToggleUnprocessed = (invitation: Invitation) => {
        switch (invitation.realEstateType) {
            case RealEstateType.RRE:
            case RealEstateType.CRE:
                return invitation.status === InvitationStatus.PROCESSED;
        }
    }

    return (
        <div className={styles.page}>
            <div className={styles.content}>
                <div className={styles.title}>{t('Invitations')}</div>
                <div className={styles.contentTop}>
                    <div className={styles.tableControls}>
                        <div className={styles.searchBar}>
                            <div className={styles.searchIconPanel}>
                                <SearchIconComponent className={styles.searchIcon}/>
                            </div>
                            <input type="text" className={styles.input}
                                   autoFocus={true}
                                   value={filter}
                                   onChange={(e) => setFilter(e.target.value)}
                                   onKeyPress={(e) => handleKeyPress(e)}
                                   placeholder={searchText}
                            />
                        </div>
                        <div className={styles.sorting}>
                            <DropdownComponent
                                options={sortOptions}
                                selectedOptionValue={sort}
                                onSelectionChanged={(optionValue) => onSortOptionChanged(optionValue)}/>
                        </div>
                    </div>

                    <div className={styles.buttons}>
                        <ReactTooltip id="view-all" effect="solid" place="bottom"/>
                        <button className={`${styles.button} ${styles.buttonSmall}`}
                                data-tip={t('Toggle visibility of completed invitations')}
                                data-for="view-all"
                                onClick={() => setViewAll(!viewAll)}>
                            {viewAll ? <EyeIconComponent className={styles.eyeIcon}/> : <EyeSlashIconComponent className={styles.eyeIcon}/>}
                        </button>
                        <button className={styles.button}
                                onClick={() => navigateTo(RouteUrl.CreateInvitation)}>{t('Create Invite')}</button>
                        {state.userState.user?.role === UserRole.Admin && state.bankState.invitationConfiguration?.displayEmail === VariableDisplay.Required &&
                            <button className={styles.button}
                                    onClick={() => navigateTo(RouteUrl.BulkInvitation)}>{t('Bulk Invite')}
                            </button>}
                    </div>
                </div>
                <table>
                    <thead>
                    <tr>
                        {configuration && configuration.displayEmail !== VariableDisplay.Hidden && <th className={styles.extraLarge}>{t('Email')}</th>}
                        <th className={styles.extraLarge}>{t('Address')}</th>
                        <th className={styles.medium}>{bankId === VanBredaBankId ? t('KA number') : t('Reference')}</th>
                        {configuration && configuration.displayRealEstateType !== VariableDisplay.Hidden && <th className={styles.small}>{t('Type')}</th>}
                        <th className={styles.medium}>{t('Status')}</th>
                        <th className={styles.large}>{t('Created On')}</th>
                        <th className={styles.medium}>{t('Updated On')}</th>
                        {state.userState.user?.role === UserRole.Admin &&
                            <React.Fragment>
                                <th className={styles.medium}/>
                                <th className={styles.medium}/>
                                <th className={styles.extraSmall}/>
                                {state.bankState.invitationConfiguration?.displayEmail === VariableDisplay.Required && <th className={styles.extraSmall}/>}
                            </React.Fragment>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {invitations && invitations.length > 0 ? invitations.map((invitation) =>
                        <tr key={invitation.id} className={styles.row} onClick={() => openInvitation(invitation)}>
                            {configuration && configuration.displayEmail !== VariableDisplay.Hidden && <td className={styles.extraLarge}>{invitation.email}</td>}
                            <td className={styles.extraLarge}>{invitation.address}</td>
                            <td className={styles.medium}>{invitation.externalRef}</td>
                            {configuration && configuration.displayRealEstateType !== VariableDisplay.Hidden && <th className={styles.small}>{invitation.realEstateType}</th>}
                            <td className={styles.medium}>{invitation.status}</td>
                            <td className={styles.large}>{new Date(invitation.createdOn).toLocaleDateString()}</td>
                            <td className={styles.medium}>{new Date(invitation.updatedOn).toLocaleDateString()}</td>
                            {state.userState.user?.role === UserRole.Admin &&
                                <React.Fragment>
                                    <td className={styles.small}>
                                        {invitation.status === InvitationStatus.COMPLETED ?
                                            downloadIds.includes(invitation.token) ?
                                                <div className={styles.loadingIcon}>
                                                    <LoaderComponent/>
                                                </div> :
                                                <span className={styles.download}
                                                      onClick={(event) => downloadFiles(event, invitation.token)}>{t('Download')}</span> : ''}
                                    </td>
                                    <td className={styles.medium}>
                                        {canToggleProcessed(invitation) &&
                                                <span className={styles.download}
                                                      onClick={(event) => toggleProcessed(event, invitation.id)}>{t('Processed')}</span>}
                                        {canToggleUnprocessed(invitation) &&
                                                <span className={styles.download}
                                                      onClick={(event) => toggleProcessed(event, invitation.id)}>{t('Unprocessed')}</span>}
                                    </td>
                                    <td className={styles.extraSmall}>
                                        {state.userState.user?.role === UserRole.Admin ?
                                            <span className={styles.iconContainer}
                                                  onClick={(event) => openConfirmDeletePopup(event, invitation)}>
                                                <TrashIconComponent className={styles.icon}/>
                                            </span> : ''}
                                    </td>
                                    {state.bankState.invitationConfiguration?.displayEmail === VariableDisplay.Required &&
                                    <td className={styles.extraSmall}>
                                        {invitation.status !== InvitationStatus.COMPLETED && invitation.status !== InvitationStatus.PROCESSED ?
                                            <span className={styles.iconContainer}
                                                  onClick={(event) => openConfirmResendPopup(event, invitation)}>
                                                <PaperPlaneIconComponent className={styles.icon}/>
                                            </span> : ''}
                                    </td>}
                                </React.Fragment>}
                        </tr>) : <tr>
                        <td colSpan={8} className={styles.empty}>{inProgress ? <div className={styles.loadingIcon}>
                            <LoaderComponent/>
                        </div> : t('No invitations found')}</td>
                    </tr>}
                    </tbody>
                </table>
                {invitations ? <PagingComponent currentPage={page}
                                                initialItemsPerPage={itemsPerPage}
                                                onPreviousPage={() => onPreviousPage()}
                                                onNextPage={() => onNextPage()}
                                                onJumpToPage={(index: number) => onJumpToPage(index)}
                                                total={totalItems}
                                                onItemsPerPageChanged={(items: number) => onItemsPerPageChanged(items)}
                /> : null}
            </div>
        </div>
    )
}

export default InvitationsPage;
