import {InvitationSortOrder} from '../types/invitation-sort-order';
import {Order, SortingOptions, SortType} from '../types/request/invitations-request';

export class InvitationSortOrderMapper {
    public static mapToSortingOptions(type: InvitationSortOrder): SortingOptions {
        switch (type) {
            case InvitationSortOrder.AddressAscending:
                return {
                    sortType: SortType.Address,
                    orderBy: Order.Ascending,
                };
            case InvitationSortOrder.AddressDescending:
                return {
                    sortType: SortType.Address,
                    orderBy: Order.Descending,
                };
            case InvitationSortOrder.EmailAscending:
                return {
                    sortType: SortType.Email,
                    orderBy: Order.Ascending,
                };
            case InvitationSortOrder.EmailDescending:
                return {
                    sortType: SortType.Email,
                    orderBy: Order.Descending,
                };
            case InvitationSortOrder.StatusAscending:
                return {
                    sortType: SortType.Status,
                    orderBy: Order.Ascending,
                };
            case InvitationSortOrder.StatusDescending:
                return {
                    sortType: SortType.Status,
                    orderBy: Order.Descending,
                };
            default:
            case InvitationSortOrder.UpdatedOnAscending:
                return {
                    sortType: SortType.UpdatedOn,
                    orderBy: Order.Ascending,
                };
            case InvitationSortOrder.UpdatedOnDescending:
                return {
                    sortType: SortType.UpdatedOn,
                    orderBy: Order.Descending,
                };
        }
    }
}
