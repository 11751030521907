import {loginRequest, msalInstance} from '../../auth-config';
import {InteractionRequiredAuthError} from "@azure/msal-browser";
import {RuntimeConfig} from "../../config/runtime-config";

class AuthenticationApi {
    public static async getJwtIdToken(): Promise<string> {
        const account = msalInstance.getActiveAccount();
        if (account) {
            const response = await msalInstance.acquireTokenSilent({
                ...loginRequest,
                account,
            }).catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                    return await msalInstance.acquireTokenPopup({
                        scopes: [RuntimeConfig.getApiScope()]
                    }).catch(error => {
                        msalInstance.logoutRedirect({
                            account: account,
                            postLogoutRedirectUri: RuntimeConfig.getRedirectUri()
                        });
                        return error;
                    });
                } else {
                    msalInstance.logoutRedirect({
                        account: account,
                        postLogoutRedirectUri: RuntimeConfig.getRedirectUri()
                    });
                }
            });
            return response?.accessToken;
        } else {
            return '';
        }
    }
}

export default AuthenticationApi;
