import {Configuration, LogLevel, PublicClientApplication} from '@azure/msal-browser';
import {RuntimeConfig} from './config/runtime-config';

const msalConfig: Configuration = {
    auth: {
        clientId: RuntimeConfig.getClientId(),
        authority: RuntimeConfig.getAuthority(),
        redirectUri: RuntimeConfig.getRedirectUri(),
        postLogoutRedirectUri: RuntimeConfig.getRedirectUri(),
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            logLevel: LogLevel.Error,
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                }
            }
        }
    }
};

export const msalInstance = new PublicClientApplication(msalConfig);


export const loginRequest = {
    scopes: [RuntimeConfig.getApiScope()],
};
