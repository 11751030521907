import React, {FunctionComponent} from 'react';
import {VariableDisplay} from '../../types/variable-display';
import styles from './input.module.scss';
import ReactTooltip from 'react-tooltip';
import exclamationMarkIcon from '../../../assets/images/icon_Exclamation Mark.svg';
import {useTranslation} from 'react-i18next';
import DropdownComponent, {SelectionOption} from '../dropdown/dropdown.component';

const LabeledDropdownComponent: FunctionComponent<OwnProps> = ({
                                                                   id,
                                                                   label,
                                                                   display,
                                                                   errors,
                                                                   options,
                                                                   value,
                                                                   valueChanged,
                                                                   footnote,
                                                               }: OwnProps) => {
    const {t} = useTranslation();

    const displayProperty = (propertyDisplay: VariableDisplay): boolean => {
        return propertyDisplay === VariableDisplay.Required || propertyDisplay === VariableDisplay.Optional;
    }

    const isOptional = (propertyDisplay: VariableDisplay): boolean => {
        return propertyDisplay === VariableDisplay.Optional;
    }

    return displayProperty(display) ? (<div className={styles.formField}>
            <div className={styles.labelContainer}>
                <label className={styles.label} htmlFor={id}>
                    {label}
                    {isOptional(display) &&
                    <span className={styles.floatRight}>
                        <small>{t('Optional')}</small>
                    </span>}
                </label>
            </div>
            <div className={styles.inputContainer}>
                <div className={styles.dropdownInput}>
                    <DropdownComponent invalid={!!errors}
                                       options={options}
                                       onSelectionChanged={valueChanged}
                                       selectedOptionValue={value}/>
                </div>
                {errors &&
                    <div className={styles.invalid}>
                        <ReactTooltip effect="solid"/>
                        <img src={exclamationMarkIcon} data-tip={errors}/>
                    </div>}
            </div>
            {footnote && <div className={styles.footnote}>{footnote}</div>}
        </div>
    ) : <></>;
}
export default LabeledDropdownComponent;

type OwnProps = {
    id: string;
    label: string;
    display: VariableDisplay;
    errors?: string;
    options: SelectionOption[]
    value?: string;
    valueChanged: (value: string) => void;
    footnote?: string;
}
