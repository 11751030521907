import { VariableDisplay } from '../../types/variable-display';
import React from 'react';
import styles from './config-field.module.scss';
import DropdownComponent, {
	SelectionOption,
} from '../../components/dropdown/dropdown.component';
import ReactTooltip from 'react-tooltip';
import { ReactComponent as Info } from '../../../assets/images/info.svg';
import { v4 as uuidv4 } from 'uuid';

const ConfigDropdownComponent: <T extends VariableDisplay | string>(
    {
        editMode,
        value,
        label,
        onValueChanged,
        infoText,
        selectionOptions
    }: OwnProps<T>) => JSX.Element = <T extends VariableDisplay | string>({
	editMode,
	value,
	label,
	onValueChanged,
	infoText,
	selectionOptions,
}: OwnProps<T>) => {
	const uuid = uuidv4();

	const valueChanged = (value: T) => {
		onValueChanged(value);
	};

	return (
		<div className={styles.configurationField}>
			<div className={styles.label}>
				{label}:
				{editMode && infoText && (
					<div className={styles.infoIconBox}>
						<ReactTooltip id={uuid} effect="solid" place="right" />
						<Info
							className={styles.infoIcon}
							data-tip={infoText}
							data-for={uuid}
						/>
					</div>
				)}
			</div>
			{!editMode && <div className={styles.value}>{value}</div>}
			{editMode && (
				<DropdownComponent
					options={selectionOptions}
					width={'150px'}
					selectedOptionValue={value}
					//@ts-expect-error
					onSelectionChanged={valueChanged}
				/>
			)}
		</div>
	);
};
export default ConfigDropdownComponent;

type OwnProps<T> = {
	editMode: boolean;
	value: T;
	onValueChanged: (value: T) => void;
	label: string;
	infoText?: string;
	selectionOptions: SelectionOption[];
};
