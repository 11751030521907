import {SelectionOption} from './dropdown.component';
import {TFunction} from 'react-i18next';

export function enumToSelectionOptions<T>(enumType: T, t: TFunction): SelectionOption[] {
    const result: SelectionOption[] = [];
    Object.keys(enumType).forEach(key => result.push({
            value: key,
            label: t(key),
            key
        }));
    return result;
}

export function enumToSelectionOptionsWithExcluding<T extends { [name: string]: any }>(enumType: T, t: TFunction, excluding: string[]): SelectionOption[] {
    const result: SelectionOption[] = [];
    Object.keys(enumType).filter(key => !excluding.find(ex => ex === key)).forEach(key => result.push({
        value: enumType[key],
        label: t(key),
        key
    }));
    return result;
}
