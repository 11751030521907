import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import Routes from './routes';
import { AppContext } from './store/app-context';
import AppHeader from "./components/app-header/app-header.component";

const Main: FunctionComponent = () => {
    const { state } = useContext(AppContext);
    const [showHeader, setShowHeader] = useState<boolean>(state.layoutState.showHeader);

    useEffect(() => {
        setShowHeader(state.layoutState.showHeader);
    }, [state.layoutState.showHeader]);

    return (
        <div>
            {showHeader ? <AppHeader/> : '' }
            <Routes/>
        </div>
    );
};

export default Main;
