import React, {FunctionComponent} from 'react';
import styles from './toast-message.module.scss';
import {conditionalClassLister} from "../../utils/class-helper";
import {ToastMessageType} from "../../types/toast";

const ToastMessageComponent: FunctionComponent<OwnProps> = ({message, type}: OwnProps) => {

    const headerStyles = conditionalClassLister(styles)({
        header: true,
        error: type === ToastMessageType.Error,
        warning: type === ToastMessageType.Warning,
        success: type === ToastMessageType.Success
    });
    return (
        <div className={styles.container}>
            <div className={headerStyles}>{type[0].toUpperCase().concat(type.slice(1))}</div>
            <div className={styles.content}>{message}</div>
        </div>
    );
}

export default ToastMessageComponent;

interface OwnProps {
    message: string;
    type: string;
}