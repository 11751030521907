import React, {FunctionComponent} from 'react';
import styles from './dropdown.module.scss';
import {useTranslation} from 'react-i18next';
import {conditionalClassLister} from '../../utils/class-helper';

const DropdownComponent: FunctionComponent<OwnProps> = (props: OwnProps) => {
    const {t} = useTranslation();
    const {options, width, onSelectionChanged, selectedOptionValue, invalid} = props;

    const panelClasses = conditionalClassLister(styles)({
        selectionPanel: true,
        invalid: invalid,
    });

    return (
        <div className={styles.dropdownPanel}
             style={width ? {width} : {minWidth:'150px'}}>
            <select className={panelClasses} value={selectedOptionValue || 'placeholder'} onChange={(e) => onSelectionChanged(e.target.value)}>
                <option className={styles.hiddenOption} key={'placeholder'} value={'placeholder'} disabled>{t('Pick an option')}</option>
                {options.map((option) =>
                    <option key={option.key ? option.key : option.value} value={option.value}>{option.label}</option>)}
            </select>
        </div>
    );
}
export default DropdownComponent;

interface OwnProps {
    options: SelectionOption[];
    onSelectionChanged: (value: string) => void;
    selectedOptionValue?: string;
    width?: string;
    disabled?: boolean;
    invalid?: boolean;
}

export interface SelectionOption {
    value?: string;
    label: string;
    key?: string;
}
